import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function RSDateField(props) {
  let date = dayjs();
  if (props.defaultValue) {
    date = dayjs(props.defaultValue);
  }
  const [value, setValue] = useState(date);

  const onChange = (newValue) => {
    setValue(dayjs(newValue));
    if (props.onChange) {
      props.onChange(props.fieldName, newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={props.label}
        format="YYYY-MM-DD"
        value={value}
        onChange={onChange}
        name={props.fieldName}
      />
    </LocalizationProvider>
  );
}

RSDateField.propTypes = {
  fieldName: PropTypes.string,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
};
