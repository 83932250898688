import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import { Container } from "@mui/material";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import ShowPageDetails from "../../components/common/ShowPageDetails";
import { rowLabel, toPascalCase } from "../../helper/TransformHelper";
import { useSelector } from "react-redux";

export default function CustomObjectsShow() {
  const params = useParams();
  const document = toPascalCase(params.customObject);
  const formFields = useSelector((state) => state.metadata[document]);
  const [customObjectDetails, setCustomObjectDetails] = useState([]);

  useEffect(() => {
    fetchCustomObjectRecord();
  }, []);
  const fetchCustomObjectRecord = () => {
    Api.get(
      `custom_objects/${params.customObject}/${params.objectId}`,
      {},
      (customObjectRecord) => {
        setCustomObjectDetails(
          formFields.map((detailField) => {
            return {
              title: detailField.label,
              value: rowLabel(customObjectRecord, detailField),
            };
          }),
        );
      },
    );
  };

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Custom Objects", link: "#" },
          {
            label: params.customObject,
            link: `/custom_objects/${params.customObject}`,
          },
          {
            label: params.objectId,
            link: `/custom_objects/${params.customObject}/${params.objectId}`,
          },
        ]}
      />
      <ShowPageTitle title={`${params.customObject} - ${params.objectId}`} />
      <ShowPageDetails values={customObjectDetails} />
    </Container>
  );
}
