import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Api } from "../../helper/Api";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { useSnackbar } from "notistack";
import { toUpperCamelCase } from "../../helper/TransformHelper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function FieldMapping(props) {
  const [fieldsMapping, setFieldsMapping] = useState(() => {
    const tempMapping = {};
    props.formFields.forEach((formField) => {
      const defaultHeader = props.headers.find((header) => {
        return (
          header === formField.fieldName ||
          header === toUpperCamelCase(formField.fieldName)
        );
      });
      tempMapping[formField.fieldName] = defaultHeader || "";
    });
    return tempMapping;
  });

  const saveMappings = () => {
    Api.put(props.mappingEndpoint, { field_mappings: fieldsMapping }, () => {
      props.setImportStep(3);
    });
  };

  return (
    <div>
      <Typography variant="h4">Map Fields</Typography>
      {props.formFields.map((formField) => {
        return (
          <div key={`header-${formField.fieldName}`}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>{formField.fieldName}</InputLabel>
              <Select
                value={fieldsMapping[formField.fieldName]}
                onChange={(event) => {
                  const tempMapping = Object.assign({}, fieldsMapping);
                  tempMapping[formField.fieldName] = event.target.value;
                  setFieldsMapping(tempMapping);
                }}
                label={formField.fieldName}
                name={formField.fieldName}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {props.headers?.map((header) => {
                  return (
                    <MenuItem
                      key={`menu-${formField.fieldName}-${header}`}
                      value={header}
                    >
                      {header}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      })}
      <Button variant="contained" onClick={saveMappings}>
        Next
      </Button>
    </div>
  );
}

FieldMapping.propTypes = {
  headers: PropTypes.array,
  formFields: PropTypes.array,
  mappingEndpoint: PropTypes.string,
  setImportStep: PropTypes.func,
};

function StartImport(props) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleClick() {
    setLoading(true);

    Api.post(
      props.startImportUrl,
      {},
      () => {
        enqueueSnackbar("Successfully imported", {
          variant: "success",
        });
        props.finishCallback();
      },
      (errorData) => {
        enqueueSnackbar(errorData.message, {
          variant: "error",
        });
        props.finishCallback();
      },
    );
  }

  return (
    <LoadingButton
      size="small"
      onClick={handleClick}
      endIcon={<SendIcon />}
      loading={loading}
      loadingPosition="end"
      variant="contained"
    >
      <span>Start Import</span>
    </LoadingButton>
  );
}

StartImport.propTypes = {
  startImportUrl: PropTypes.string,
  finishCallback: PropTypes.func,
};

export default function ImportDialogue(props) {
  const [fileHeaders, setFileHeaders] = useState([]);
  const [importId, setImportId] = useState(0);
  const [importStep, setImportStep] = useState(1);

  const uploadFile = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.set("document", props.document);
      formData.set("import_file", file);
      Api.post(props.endpoint, formData, (data) => {
        setFileHeaders(data.headers);
        setImportId(data.id);
        setImportStep(2);
      });
    }
  };

  return (
    <Dialog fullScreen open={props.open} onClose={props.handleClose}>
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="85vh"
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
          marginTop: 8,
          paddingTop: 2,
          flexDirection: "column",
          "& .MuiFormControl-root": {
            tablet: {
              marginTop: 10,
              marginLeft: 10,
              minHeight: 80,
              width: "48%",
            },
            mobile: { marginTop: 15, width: "100%" },
          },
        }}
      >
        {importStep === 1 ? (
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              onChange={uploadFile}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </Button>
        ) : (
          ""
        )}

        {importStep === 2 ? (
          <FieldMapping
            headers={fileHeaders}
            formFields={props.formFields}
            mappingEndpoint={`${props.endpoint}/${importId}`}
            setImportStep={setImportStep}
          />
        ) : (
          ""
        )}

        {importStep === 3 ? (
          <StartImport
            startImportUrl={`${props.endpoint}/${importId}/start`}
            finishCallback={() => {
              setImportStep(1);
              props.handleClose();
            }}
          />
        ) : (
          ""
        )}
      </Box>
    </Dialog>
  );
}

ImportDialogue.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  formFields: PropTypes.array,
  endpoint: PropTypes.string,
  apiAction: PropTypes.string,
  document: PropTypes.string,
};
