import { Container } from "@mui/material";
import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListHeader from "../../../components/common/ListHeader";
import ListTable from "../../../components/common/ListTable";
import { Api } from "../../../helper/Api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// const formFields = [
//   {
//     label: "Name",
//     defaultValue: "",
//     required: true,
//     fieldType: "text",
//     fieldName: "name",
//   },
//   {
//     label: "Description",
//     defaultValue: "",
//     required: false,
//     fieldType: "text",
//     fieldName: "description",
//   },
// ];

export default function InventoryLocation() {
  const [inventoryLocations, setInventoryLocations] = useState([]);

  useEffect(() => {
    refreshInventoryLocations();
  }, []);

  const refreshInventoryLocations = () => {
    Api.get("/settings/inventory_locations", {}, setInventoryLocations);
  };
  const formFields = useSelector(
    (state) => state.metadata["InventoryLocation"]
  );

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Inventory Locations", link: "#" },
        ]}
      />
      <ListHeader
        title="Inventory Location"
        addCallback={refreshInventoryLocations}
        formFields={formFields}
        endpoint="/settings/inventory_locations"
        variant="h1"
        document="InventoryLocation"
      />
      <ListTable
        fields={formFields}
        rows={inventoryLocations}
        editEndpoint="/settings/inventory_locations/{id}"
        deleteEndpoint="/settings/inventory_locations/{id}"
        deleteCallback={refreshInventoryLocations}
        hiddenActions={["view"]}
        editCallback={refreshInventoryLocations}
        title="Inventory Location"
      />
    </Container>
  );
}
