import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ListHeader from "../../components/common/ListHeader";
import ListTable from "../../components/common/ListTable";
import { Api } from "../../helper/Api";

export default function InventoryList() {
  const [inventories, setInventories] = useState([]);

  useEffect(() => {
    refreshInventories();
  }, []);

  const refreshInventories = () => {
    Api.get("/inventories", {}, setInventories);
  };

  const formFields = useSelector((state) => state.metadata["Inventory"]);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb menuStack={[{ label: "Inventory", link: "#" }]} />
      <ListHeader
        title="Inventories"
        addCallback={refreshInventories}
        formFields={formFields}
        filterCallback={refreshInventories}
        endpoint="/inventories"
        variant="h1"
        document="Inventory"
      />
      <ListTable
        fields={formFields}
        rows={inventories}
        editEndpoint="/inventories/{id}"
        deleteEndpoint="/inventories/{id}"
        deleteCallback={refreshInventories}
        hiddenActions={["view"]}
        editCallback={refreshInventories}
        title="Inventory"
        hideActions={true}
      />
    </Container>
  );
}
