import { Box } from "@mui/material";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import PropTypes from "prop-types";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import ListTable from "../../components/common/ListTable";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";

const appliedInvoiceFields = [
  {
    label: "Invoice ID",
    defaultValue: "",
    fieldType: "text",
    fieldName: "document_identifier",
    displayOnly: true,
  },
  {
    label: "Applied Amount",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "applied_amount",
    isNumber: true,
    isCurrency: true,
  },
  {
    label: "Applied On",
    defaultValue: "",
    fieldType: "datetime",
    fieldName: "applied_on",
    displayOnly: true,
  },
];
export default function PaymentAssociation(props) {
  const [appliedInvoices, setAppliedInvoices] = useState([]);
  useEffect(() => {
    Api.get(
      `/payments/${props.paymentId}/applied_invoices`,
      {},
      setAppliedInvoices
    );
  }, []);

  return (
    <Box sx={{ mt: 3, width: "100%", typography: "body1" }}>
      <TabContext value="invoices">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList aria-label="Associations">
            <Tab label="Applied Invoices" value="invoices" />
          </TabList>
        </Box>
        <TabPanel value="invoices">
          <ShowPageTitle title="Applied Invoices" variant="h6" />
          <ListTable
            fields={appliedInvoiceFields}
            rows={appliedInvoices}
            title="Applied Invoices"
            hideActions={true}
            showEndpoint="/invoices/{id}"
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

PaymentAssociation.propTypes = {
  paymentId: PropTypes.string,
};
