import { Box, Typography } from "@mui/material";

export default function PrivacyPolicy() {
  return (
    <Box>
      <Typography variant="h1" sx={{ marginBottom: 5, fontSize: 50 }}>
        Raseeth Technologies - Privacy Policy
      </Typography>
      <div>
        <p>
          At Raseeth Technologies, we are committed to protecting the privacy
          and security of our customers' personal information. This Privacy
          Policy outlines the types of personal information we collect, how we
          use and protect that information, and your rights with respect to your
          personal information.
        </p>
        <h5>Information We Collect</h5>

        <p>
          We collect various types of personal information from our customers in
          order to provide our services. This includes:
        </p>
        <ul>
          <li>
            Name and contact information (such as email address and phone
            number)
          </li>
          <li>Business information (such as business name and address)</li>
          <li>Payment information (such as credit card details)</li>
        </ul>

        <h5>How We Use Your Information</h5>

        <p>
          We use the information we collect from you for the following purposes:
        </p>
        <ul>
          <li>To provide you with our services and to fulfill your orders</li>
          <li>To process your payments</li>
          <li>To communicate with you about your account and our services</li>
          <li>To improve our services and develop new ones</li>
          <li>To comply with legal and regulatory requirements</li>
        </ul>

        <p>
          We will never sell or share your personal information with third
          parties for their own marketing purposes.
        </p>

        <h5>Data Security</h5>

        <p>
          We take data security very seriously and have implemented appropriate
          technical and organizational measures to protect your personal
          information from unauthorized access, use, or disclosure. We regularly
          review and update our security practices to ensure the ongoing
          protection of your personal information.
        </p>

        <h5>Your Rights</h5>

        <p>
          You have the right to access, correct, or delete your personal
          information at any time. You may also request that we restrict the
          processing of your personal information or object to our processing of
          your personal information. To exercise any of these rights, please
          contact us at the contact information provided below.
        </p>

        <h5>Changes to This Policy</h5>

        <p>
          We may update this Privacy Policy from time to time. If we make any
          material changes to this Policy, we will notify you by email or by
          posting a notice on our website prior to the effective date of the
          changes.
        </p>

        <h5>Contact Us</h5>

        <p>
          If you have any questions or concerns about our Privacy Policy or our
          data practices, please contact us at:
          <br />
          <br />
          <b>Raseeth Technologies</b>
          <br />
          Kollarukandiyil,
          <br />
          Padanilam Post
          <br />
          Calicut, India 673571
          <br />
          Email: privacy@raseeth.com
          <br />
        </p>
        <p>Effective Date: February 24, 2023.</p>
      </div>
    </Box>
  );
}
