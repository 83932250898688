import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ShowPageTitle from "../../../components/common/ShowPageTitle";
import { Box, Button, Container } from "@mui/material";
import Form from "../../../components/common/Form";
import { useEffect, useState } from "react";
import { Api } from "../../../helper/Api";
import { useSnackbar } from "notistack";
import { storeGeneralSettings } from "../../../redux/store";
import { useDispatch } from "react-redux";

const formFields = [
  {
    label: "Entity Name",
    defaultValue: "",
    fieldType: "text",
    fieldName: "name",
  },
  {
    label: "Logo",
    defaultValue: "",
    fieldType: "file",
    fieldName: "logo",
  },
  {
    label: "Email",
    defaultValue: "",
    fieldType: "text",
    fieldName: "email",
  },
  {
    label: "Phone",
    defaultValue: "",
    fieldType: "text",
    fieldName: "phone",
  },
  {
    label: "Address Line 1",
    defaultValue: "",
    fieldType: "text",
    fieldName: "address",
  },
  {
    label: "Address Line 2",
    defaultValue: "",
    fieldType: "text",
    fieldName: "street",
  },
  {
    label: "Zip Code / Postal Code",
    defaultValue: "",
    fieldType: "text",
    fieldName: "zip_code",
  },
  {
    label: "State",
    defaultValue: "",
    required: false,
    fieldType: "select",
    fieldName: "state",
    optionEndpoint: "/geographies/states?country_code=IND",
  },
  {
    label: "Country",
    defaultValue: "IND",
    required: false,
    fieldType: "select",
    fieldName: "country",
    optionEndpoint: "/geographies/countries",
  },
  {
    label: "Default Currency",
    fieldType: "select",
    fieldName: "currency_id",
    defaultValue: "",
    availableValues: [],
  },
];

export default function CompanyProfile() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    Api.post("/settings/general_settings", formData, (data) => {
      setEntityDetails(data);
      enqueueSnackbar("Your have updated company profile successfully", {
        variant: "success",
      });
      dispatch(storeGeneralSettings(data));
    });
  };

  const [entityDetails, setEntityDetails] = useState({});
  const [entityFormFields, setEntityFormFields] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (currencies.length < 1) {
      return;
    }

    setEntityFormFields(
      formFields.map((field) => {
        field.defaultValue = `${entityDetails[field.fieldName]}`;
        if (field.fieldName == "currency_id") {
          field.availableValues = currencies;
          const indianRupee = currencies.find(
            (currency) => currency.label === "Indian rupee",
          );
          if (!field.defaultValue && indianRupee) {
            field.defaultValue = `${indianRupee.id}`;
          }
        }
        return field;
      }),
    );
  }, [currencies]);

  useEffect(() => {
    getEntityDetails();
  }, []);

  const getEntityDetails = () => {
    Api.get("/settings/general_settings", {}, (data) => {
      setEntityDetails(data);
      getCurrencies();
    });
  };

  const getCurrencies = () => {
    Api.get("/autocomplete?object=Currency", {}, setCurrencies);
  };

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Company Profile", link: "" },
        ]}
      />
      <ShowPageTitle title="Company Profile" />
      <Box
        sx={{
          mt: 3,
          minHeight: "70vh",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Form
          formFields={entityFormFields}
          handleSubmit={handleSubmit}
          formId="general-settings"
        />
        <Box style={{ clear: "both", textAlign: "center" }}>
          <Button type="submit" form="general-settings" variant="contained">
            Update
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
