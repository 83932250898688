import { useEffect, useState } from "react";
import { Button, Chip, Grid, Typography } from "@mui/material";
import RSTextField from "../inputs/RSTextField";
import RSSelectInput from "../inputs/RSSelectInput";
import PropTypes from "prop-types";
import RSDateField from "../inputs/RSDateField";
import RSDateRangeField from "../inputs/RSDateRangeField";
import RSTextRangeField from "../inputs/RSTextRangeField";

export default function FilterRow(props) {
  const [selectedField, setSelectedField] = useState({});
  const [selectedOperator, setSelectedOperator] = useState({});
  const [hideOr, setHideOr] = useState(false);
  const [operators, setOperators] = useState([]);
  const [defaultOperatorValue, setDefaultOperatorValue] = useState("");
  const [valueCount, setValueCount] = useState(1);
  const [formFieldDisplay, setFormFieldDisplay] = useState("block");

  const [detailDisplay, setDetailDisplay] = useState("none");

  const changeSelectedField = (filedName) => {
    setSelectedField(
      props.filterFields.find((field) => field.id === filedName)
    );
  };

  const changeSelectedOperator = (operatorValue) => {
    const operator = operators.find((field) => field.id === operatorValue);
    if (operator) {
      setSelectedOperator(operator);
      setDefaultOperatorValue(operatorValue);
    } else {
      setSelectedOperator({ id: "" });
      setDefaultOperatorValue("");
    }
  };

  useEffect(() => {
    const defaultValueLength = props.defaultValues.length;
    if (
      selectedOperator &&
      [
        "today",
        "yesterday",
        "this_month",
        "this_year",
        "last_year",
        "this_quarter",
        "last_quarter",
      ].includes(selectedOperator.id)
    ) {
      setValueCount(0);
      setHideOr(true);
    } else if (defaultValueLength === 0) {
      setValueCount(1);
      setHideOr(false);
    } else {
      if (selectedOperator && selectedOperator.id === "between") {
        setValueCount(defaultValueLength / 2);
        setHideOr(false);
      } else {
        setValueCount(defaultValueLength);
        setHideOr(false);
      }
    }
  }, [selectedOperator, props.defaultValues]);

  useEffect(() => {
    setDefaultOperatorValue("");
    if (selectedField.fieldType === "text") {
      setOperators([
        { id: "equal", label: "Equal" },
        { id: "not_equal", label: "Not Equal" },
        { id: "contains", label: "Contains" },
        { id: "not_contains", label: "Not Contains" },
      ]);
    } else if (selectedField.fieldType === "radio") {
      setOperators([
        { id: "equal", label: "Equal" },
        { id: "not_equal", label: "Not Equal" },
      ]);
    } else if (["date", "datetime"].includes(selectedField.fieldType)) {
      setOperators([
        { id: "today", label: "Today" },
        { id: "yesterday", label: "Yesterday" },
        { id: "this_month", label: "This Month" },
        { id: "this_year", label: "This Year" },
        { id: "last_year", label: "Last Year" },
        { id: "this_quarter", label: "This Quarter" },
        { id: "last_quarter", label: "Last Quarter" },
        { id: "equal", label: "Equal" },
        { id: "not_equal", label: "Not Equal" },
        { id: "greater_than", label: "Greater Than" },
        { id: "lesser_than", label: "Lesser Than" },
        { id: "greater_than_or_equal", label: "Greater Than Or Equal" },
        { id: "lesser_than_or_equal", label: "Lesser Than Or Equal" },
        { id: "between", label: "Between" },
      ]);
    } else if (["number"].includes(selectedField.fieldType)) {
      setOperators([
        { id: "equal", label: "Equal" },
        { id: "not_equal", label: "Not Equal" },
        { id: "greater_than", label: "Greater Than" },
        { id: "lesser_than", label: "Lesser Than" },
        { id: "greater_than_or_equal", label: "Greater Than Or Equal" },
        { id: "lesser_than_or_equal", label: "Lesser Than Or Equal" },
        { id: "between", label: "Between" },
      ]);
    } else {
      setOperators([]);
    }
  }, [selectedField]);

  useEffect(() => {
    if (props.defaultFieldValue) {
      changeSelectedField(props.defaultFieldValue);
    }
  }, [props.defaultFieldValue]);

  useEffect(() => {
    if (props.defaultOperatorValue) {
      changeSelectedOperator(props.defaultOperatorValue);
    }
  }, [operators, props.defaultOperatorValue]);

  useEffect(() => {
    if (props.hide) {
      hideForm();
    } else {
      hideDetail();
    }
  }, [props.hide]);

  const hideDetail = () => {
    setFormFieldDisplay("block");
    setDetailDisplay("none");
  };

  const hideForm = () => {
    setFormFieldDisplay("none");
    setDetailDisplay("block");
  };

  const textNumberField = (keyIndex, display, defaultValue, operator) => {
    return (
      <Grid
        key={`filter-value-box${keyIndex}`}
        mobile={12}
        tablet={12}
        sx={{ mt: 1, mb: 1, display: display }}
      >
        {operator && operator.id === "between" ? (
          <RSTextRangeField
            defaultValue={defaultValue}
            fieldName="filters[][values]"
          />
        ) : (
          <RSTextField
            defaultValue={defaultValue}
            fieldName="filters[][values][]"
            label="Value"
          />
        )}
      </Grid>
    );
  };

  const dateField = (keyIndex, display, defaultValue, operator) => {
    return (
      <Grid
        key={`filter-value-box${keyIndex}`}
        mobile={12}
        tablet={12}
        sx={{ mt: 1, mb: 1, display: display }}
      >
        {operator && operator.id === "between" ? (
          <RSDateRangeField
            fieldName="filters[][values]"
            label="Value"
            defaultValue={defaultValue}
          />
        ) : (
          <RSDateField
            fieldName="filters[][values][]"
            label="Value"
            defaultValue={defaultValue}
          />
        )}
      </Grid>
    );
  };

  const filterInputField = (
    keyIndex,
    fieldType,
    display,
    defaultValue,
    operator
  ) => {
    if (
      fieldType === "text" ||
      fieldType === "number" ||
      fieldType === "radio"
    ) {
      return textNumberField(keyIndex, display, defaultValue, operator);
    } else if (fieldType === "date") {
      return dateField(keyIndex, display, defaultValue, operator);
    }
  };

  return (
    <Grid
      container
      sx={{ border: "1px solid rgba(224, 224, 224, 1)", p: 1 }}
      key={props.rowKey}
    >
      <Grid
        mobile={12}
        tablet={12}
        sx={{ mt: 1, mb: 1, display: formFieldDisplay }}
      >
        <RSSelectInput
          label="Select Field"
          fieldName="filters[][field]"
          availableValues={props.filterFields}
          onChange={(_fieldName, newValue) => {
            changeSelectedField(newValue);
          }}
          defaultValue={props.defaultFieldValue}
        />
      </Grid>
      <Grid
        mobile={12}
        tablet={12}
        sx={{ mt: 1, mb: 1, display: formFieldDisplay }}
      >
        <RSSelectInput
          label="Operator"
          fieldName="filters[][operator]"
          availableValues={operators}
          defaultValue={defaultOperatorValue}
          onChange={(_fieldName, newValue) => {
            changeSelectedOperator(newValue);
          }}
        />
      </Grid>

      {Array.from(Array(valueCount).keys()).map((idx) => {
        let defaultValue;
        if (selectedOperator && selectedOperator.id === "between") {
          defaultValue = [];
          if (props.defaultValues) {
            const selectedValue = props.defaultValues.slice(idx, idx + 2);
            if (selectedValue) {
              defaultValue = selectedValue;
            }
          }
        } else {
          defaultValue = "";
          const selectedValue = props.defaultValues[idx];
          if (selectedValue) {
            defaultValue = selectedValue;
          }
        }

        return filterInputField(
          `${selectedField.fieldName}-${idx}`,
          selectedField.fieldType,
          formFieldDisplay,
          defaultValue,
          selectedOperator
        );
      })}
      <Grid
        mobile={12}
        tablet={12}
        sx={{ mt: 1, mb: 1, display: detailDisplay }}
      >
        <Chip
          label={`${selectedField.label} ${selectedOperator.label} ...`}
          onClick={hideDetail}
        />
      </Grid>
      {hideOr ? (
        ""
      ) : (
        <Button
          size="small"
          color="secondary"
          onClick={() => {
            setValueCount(valueCount + 1);
          }}
          sx={{ display: formFieldDisplay }}
        >
          OR
        </Button>
      )}
    </Grid>
  );
}

FilterRow.propTypes = {
  filterFields: PropTypes.arrayOf(PropTypes.object),
  hide: PropTypes.bool,
  rowKey: PropTypes.string,
  defaultFieldValue: PropTypes.string,
  defaultOperatorValue: PropTypes.string,
  defaultValues: PropTypes.array,
};
