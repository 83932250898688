import { useParams } from "react-router-dom";
import { Api } from "../../helper/Api";
import { useEffect, useState } from "react";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import { Container, Grid, Paper, Typography } from "@mui/material";
import ShowPageDetails from "../../components/common/ShowPageDetails";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import CustomerAssociations from "./CustomerAssociations";
import useShowField from "../../helper/fields/showMetadata";
import { useCurrencyDisplay, useCurrencySymbol } from "../../helper/currencies";
import { rowLabel } from "../../helper/TransformHelper";

export default function CustomerShow() {
  const [customer, setCustomer] = useState({});
  const [customerDetails, setCustomerDetails] = useState([]);
  const customerFields = useShowField("Customer");
  const { id } = useParams();
  const currencyDisplay = useCurrencyDisplay();
  const currencySymbol = useCurrencySymbol();

  useEffect(() => {
    refreshCustomer();
  }, []);

  const refreshCustomer = () => {
    Api.get(`/customers/${id}`, {}, setCustomer);
  };

  useEffect(() => {
    setCustomerDetails(
      customerFields.map((detailField) => {
        return {
          title: detailField.label,
          value: rowLabel(customer, detailField, currencySymbol),
        };
      })
    );
  }, [customer]);

  const balanceDetails = () => {
    return (
      <Paper variant="outlined" square sx={{ minHeight: 100 }}>
        <Grid container sx={{ padding: "20px" }}>
          <Grid mobile={12} tablet={3} justifyContent="left">
            <Typography variant="h5">
              {currencyDisplay(customer.balance)}
            </Typography>
            <Typography variant="subtitle1">Balance</Typography>
          </Grid>
          <Grid mobile={12} tablet={3} justifyContent="left">
            <Typography variant="h5">
              {currencyDisplay(customer.payment_received)}
            </Typography>
            <Typography variant="subtitle1">Payment Received</Typography>
          </Grid>
          <Grid mobile={12} tablet={3} justifyContent="left">
            <Typography variant="h5" sx={{ fontColor: "" }}>
              {currencyDisplay(customer.payment_sent)}
            </Typography>
            <Typography variant="subtitle1">Payment Sent</Typography>
          </Grid>
          <Grid mobile={12} tablet={3} justifyContent="left">
            <Typography variant="h5" sx={{ fontColor: "" }}>
              {currencyDisplay(customer.invoiced_charges)}
            </Typography>
            <Typography variant="subtitle1">Invoiced Charges</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Customers", link: "/customers" },
          { label: customer.document_identifier, link: "/customers" },
        ]}
      />
      <ShowPageTitle title={customer.name} />
      {balanceDetails()}
      <ShowPageDetails values={customerDetails} />
      <CustomerAssociations customerId={id} refreshCustomer={refreshCustomer} />
    </Container>
  );
}
