import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import RSSelectInput from "../../components/inputs/RSSelectInput";
import PropTypes from "prop-types";
import { Api } from "../../helper/Api";

export default function SelectTemplateDialogue(props) {
  const [open, setOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    Api.get("/settings/templates?do_not_seed=true", {}, (data) => {
      setTemplates(
        data
          .filter((template) => template.template_type === "invoice")
          .map((template) => {
            return {
              label: template.name,
              id: `${template.id}`,
            };
          })
      );
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
    props.setOpenTemplateDialogue(false);
  };

  useEffect(() => {
    if (props.open) {
      if (templates.length === 1) {
        props.onSelectedTemplate(templates[0].id);
        props.setOpenTemplateDialogue(false);
      } else {
        setOpen(props.open);
      }
    } else {
      setOpen(props.open);
    }
  }, [props.open]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a template from below list
            <br />
          </DialogContentText>
          <RSSelectInput
            name="template"
            availableValues={templates}
            label="Templates"
            onChange={(fieldName, newValue) => {
              setSelectedTemplate(newValue);
            }}
            defaultValue=""
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              props.onSelectedTemplate(selectedTemplate);
            }}
          >
            Go
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SelectTemplateDialogue.propTypes = {
  open: PropTypes.bool,
  setOpenTemplateDialogue: PropTypes.func,
  onSelectedTemplate: PropTypes.func,
};
