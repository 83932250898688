export const themeConfig = {
  palette: {
    type: "light",
    primary: {
      main: "#009689",
    },
    secondary: {
      main: "#606161",
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
    },
  },
  components: {
    MuiTabPanel: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            padding: 1,
          }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            float: "left",
            tablet: {
              width: "49%",
            },
          }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: "#606161",
          }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: ({ theme }) =>
          theme.unstable_sx({
            fontSize: "0.9rem",
          }),
      },
    },
  },
  details: {
    title: {
      fontWeight: 600,
      float: "left",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      marginBottom: "10px",
      wordBreak: "break-all",
    },
    value: {
      textTransform: "capitalize",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      marginBottom: "10px",
      paddingLeft: "2px",
      wordBreak: "break-all",
    },
  },
};
