import { Box, Grid, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

function BoxedKeyValueDisplay(props) {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid mobile={6} tablet={4} style={theme.details.title}>
        {props.title}:
      </Grid>
      <Grid mobile={6} tablet={8} style={theme.details.value}>
        {props.value}
      </Grid>
    </Grid>
  );
}

BoxedKeyValueDisplay.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
};

export default function ShowPageDetails(props) {
  return (
    <Paper variant="outlined" square sx={{ mt: 1, pb: 2 }}>
      <Grid container>
        <Grid mobile={12} tablet={6} sx={{ padding: 2 }}>
          {props.values.map((fieldValue, index) => {
            if (index % 2 === 0) {
              return (
                <BoxedKeyValueDisplay
                  title={fieldValue.title}
                  value={fieldValue.value}
                  key={`boxed-${index}`}
                />
              );
            }
          })}
        </Grid>
        <Grid mobile={12} tablet={6} sx={{ padding: 2 }}>
          {props.values.map((fieldValue, index) => {
            if (index % 2 !== 0) {
              return (
                <BoxedKeyValueDisplay
                  title={fieldValue.title}
                  value={fieldValue.value}
                  key={`boxed-${index}`}
                />
              );
            }
          })}
        </Grid>
      </Grid>
    </Paper>
  );
}

ShowPageDetails.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
};
