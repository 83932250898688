import { getDisplayCurrency } from "./currencies";

export function rowLabel(rowData, field, currencySymbol = "$") {
  if (field.isCurrency) {
    const objectCurrencySymbol = rowData["currency_symbol"];
    const currencyDisplay = getDisplayCurrency(
      objectCurrencySymbol ? objectCurrencySymbol : currencySymbol,
    );
    return currencyDisplay(getRowValue(field, rowData));
  } else if (field.fieldType === "toggle") {
    return getRowValue(field, rowData) ? "Yes" : "No";
  }
  return getRowValue(field, rowData);
}

function getRowValue(field, rowData) {
  if (field.labelField) {
    return rowData[field.labelField];
  } else {
    return rowData[field.fieldName];
  }
}

export function mapContactList(contacts) {
  return contacts.map((contact) => {
    return {
      label: `${contact.document_identifier}: ${contact.first_name} ${contact.last_name}. Mob: ${contact.mobile}`,
      id: contact.document_identifier,
    };
  });
}

export function toPascalCase(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return match.toUpperCase();
  });
}

export function toUpperCamelCase(str) {
  return str
    .split("_") // Split the string into an array of words
    .map(
      (
        word, // Map each word to a capitalized version
      ) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(""); // Join all words into a single string
}
