import { Grid, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Filter from "../../components/filters/Filter";
import { useState } from "react";
import { Api } from "../../helper/Api";
import { useCurrencyDisplay } from "../../helper/currencies";
import Card from "./Card";

export default function PaymentDashboard() {
  const [paymentInfo, setPaymentInfo] = useState({
    top_customers_received: [],
    top_customers_sent: [],
  });
  const defaultFilter = [
    {
      field: "date",
      values: [],
      operator: "today",
    },
  ];

  const currencyDisplay = useCurrencyDisplay();

  const getPaymentInfo = (filters) => {
    const paymentFilter = filters ? filters : { filters: defaultFilter };
    if (paymentFilter.filters.length === 0) {
      paymentFilter["filters"] = defaultFilter;
    }
    Api.get("/dashboards/payments", paymentFilter, setPaymentInfo);
  };

  return (
    <Grid container spacing={0.5}>
      <Grid item mobile={12} tablet={12} sx={{ mb: 2 }}>
        <Filter
          title="Payments"
          filterCallback={getPaymentInfo}
          selectedFilters={defaultFilter}
          fields={[
            {
              label: "Payment Date",
              defaultValue: "",
              required: true,
              fieldType: "date",
              fieldName: "date",
            },
          ]}
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card
          title={currencyDisplay(paymentInfo.total_payments_received)}
          subTitle="Total Payments Received"
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card
          title={currencyDisplay(paymentInfo.total_payments_sent)}
          subTitle="Total Payments Given"
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card
          title={currencyDisplay(paymentInfo.payment_balance)}
          subTitle="Balance"
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card
          title={paymentInfo.total_transactions}
          subTitle="Total Transactions"
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card title={paymentInfo.payments_received_count} subTitle="Received" />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card title={paymentInfo.payments_sent_count} subTitle="Given" />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <h4>Top 10 Customers By Payment Received</h4>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentInfo.top_customers_received.map(
                (customer_data, index) => {
                  return (
                    <TableRow key={`top-cr-${index}`}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{customer_data.id}</TableCell>
                      <TableCell>{customer_data.name}</TableCell>
                      <TableCell>
                        {currencyDisplay(customer_data.amount)}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <h4>Top 10 Customers By Payment Given</h4>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentInfo.top_customers_sent.map((customer_data, index) => {
                return (
                  <TableRow key={`top-cs-${index}`}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{customer_data.id}</TableCell>
                    <TableCell>{customer_data.name}</TableCell>
                    <TableCell>
                      {currencyDisplay(customer_data.amount)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
}
