import PropTypes from "prop-types";
import TabContext from "@mui/lab/TabContext";
import { Box } from "@mui/material";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import ListTable from "../../components/common/ListTable";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";
import ListHeader from "../../components/common/ListHeader";

const invoiceTaxFields = [
  {
    label: "Line ID",
    defaultValue: "",
    fieldType: "text",
    fieldName: "line_id",
  },
  {
    label: "Product Name",
    defaultValue: "",
    fieldType: "text",
    fieldName: "product_name",
  },
  {
    label: "Rule Name",
    defaultValue: "",
    fieldType: "text",
    fieldName: "tax_rule_name",
  },
  {
    label: "Rate ID",
    defaultValue: "",
    fieldType: "text",
    fieldName: "tax_rate_name",
  },
  {
    label: "Rate",
    defaultValue: "",
    fieldType: "text",
    fieldName: "rate",
  },
  {
    label: "Rate Type",
    defaultValue: "",
    fieldType: "text",
    fieldName: "rate_type",
  },
  {
    label: "Inclusive",
    defaultValue: "",
    fieldType: "toggle",
    fieldName: "inclusive",
  },
  {
    label: "Amount",
    defaultValue: "",
    fieldType: "text",
    fieldName: "amount",
    isCurrency: true,
  },
];

export default function InvoiceAssociation(props) {
  const [activeTab, setActiveTab] = useState("invoice_lines");
  const [invoiceLines, setInvoiceLines] = useState([]);
  const invoiceLineFields = useSelector(
    (state) => state.metadata["InvoiceLine"],
  );
  const [invoicePayments, setInvoicePayments] = useState([]);
  const invoicePaymentFields = useSelector((state) => {
    const fields = state.metadata["InvoicesPayment"];
    return fields.map((field) => {
      const newField = Object.assign({}, field);
      if (newField.fieldName === "payment_id") {
        newField.optionEndpoint = newField.optionEndpoint.replace(
          "{invoice_id}",
          props.invoiceId,
        );

        newField.addFormFields = newField.addFormFields.map((paymentField) => {
          const newPaymentField = Object.assign({}, paymentField);
          if (newPaymentField.fieldName === "customer_id") {
            newPaymentField.defaultValue = props.customerId;
            newPaymentField.disabled = true;
          }
          if (newPaymentField.fieldName === "mode") {
            newPaymentField.availableValues = ["Received"];
          }
          if (newPaymentField.fieldName === "amount") {
            newPaymentField.defaultValue = props.invoiceBalance;
          }
          return newPaymentField;
        });
      }
      return newField;
    });
  });
  const [invoiceTaxes, setInvoiceTaxes] = useState([]);

  useEffect(() => {
    Api.get(`/invoices/${props.invoiceId}/invoice_lines`, {}, setInvoiceLines);
    Api.get(`/invoices/${props.invoiceId}/invoice_taxes`, {}, setInvoiceTaxes);
    refreshInvoicePayments();
  }, []);

  const refreshInvoicePayments = () => {
    Api.get(
      `/invoices/${props.invoiceId}/invoice_payments`,
      {},
      setInvoicePayments,
    );
  };

  const addCallback = () => {
    props.refreshInvoice();
    refreshInvoicePayments();
  };

  return (
    <Box sx={{ mt: 3, width: "100%", typography: "body1" }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            aria-label="Associations"
            onChange={(event, newValue) => {
              setActiveTab(newValue);
            }}
          >
            <Tab label="Invoice Lines" value="invoice_lines" />
            <Tab label="Invoice Taxes" value="invoice_taxes" />
            <Tab label="Invoice Payments" value="invoice_payments" />
          </TabList>
        </Box>
        <TabPanel value="invoice_lines">
          <ShowPageTitle title="Invoice Lines" variant="h6" />
          <ListTable
            fields={invoiceLineFields}
            rows={invoiceLines}
            title="Invoice Lines"
            document="InvoiceLine"
            hideActions={true}
          />
        </TabPanel>
        <TabPanel value="invoice_taxes">
          <ShowPageTitle title="Invoice Taxes" variant="h6" />
          <ListTable
            fields={invoiceTaxFields}
            rows={invoiceTaxes}
            title="Invoice Taxes"
            hideActions={true}
          />
        </TabPanel>
        <TabPanel value="invoice_payments">
          <ListHeader
            variant="h6"
            document="InvoicesPayment"
            title="Invoice Payment"
            formFields={invoicePaymentFields}
            addCallback={addCallback}
            endpoint={`/invoices/${props.invoiceId}/apply_payment`}
          />
          <ListTable
            fields={invoicePaymentFields}
            rows={invoicePayments}
            title="Invoice Payment"
            document="InvoicesPayment"
            hiddenActions={["view", "edit"]}
            deleteEndpoint={`/invoices/${props.invoiceId}/delete_payment?invoices_payment_id={id}`}
            deleteCallback={addCallback}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

InvoiceAssociation.propTypes = {
  invoiceId: PropTypes.string,
  customerId: PropTypes.string,
  invoiceBalance: PropTypes.any,
  refreshInvoice: PropTypes.func,
};
