import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListTable from "../../../components/common/ListTable";
import { Container, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import ListHeader from "../../../components/common/ListHeader";
import { Api } from "../../../helper/Api";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const formFields = [
  {
    label: "Name",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "name",
  },
  {
    label: "Description",
    defaultValue: "",
    required: false,
    fieldType: "text",
    fieldName: "description",
  },
  {
    label: "Type",
    defaultValue: "invoice",
    required: false,
    fieldType: "select",
    fieldName: "template_type",
    availableValues: [
      {
        label: "Invoice",
        id: "invoice",
      },
      {
        label: "Statement",
        id: "statement",
      },
    ],
  },
  {
    label: "html",
    defaultValue: "",
    required: false,
    fieldType: "textArea",
    fieldName: "html",
    hideInList: true,
    multiline: true,
  },
];

export default function TemplateList() {
  const [templates, setTemplates] = useState([]);
  const refreshTemplateList = (params = {}) => {
    Api.get("/settings/templates", params, setTemplates);
  };

  const clickedPreview = (event) => {
    const templateId =
      event.target.parentNode.parentNode.parentNode.getAttribute(
        "actionable-object-id"
      );
    const previewUrl = `${
      process.env.API_BASE_URL
    }/settings/templates/${templateId}/preview_html?jwt_token=${localStorage.getItem(
      "access_token"
    )}`;
    window.open(previewUrl, "_blank").focus();
  };

  const actionMenu = () => {
    return [
      <MenuItem key={`preview-template-menu`} onClick={clickedPreview}>
        <PictureAsPdfIcon />
        &nbsp;Preview
      </MenuItem>,
    ];
  };

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Templates", link: "#" },
        ]}
      />
      <ListHeader
        title="Templates"
        addCallback={refreshTemplateList}
        formFields={formFields}
        endpoint="/settings/templates"
        variant="h1"
        filterCallback={refreshTemplateList}
        document="Template"
      />
      <ListTable
        fields={formFields}
        rows={templates}
        editEndpoint="/settings/templates/{id}"
        deleteEndpoint="/settings/templates/{id}"
        deleteCallback={refreshTemplateList}
        hiddenActions={["view"]}
        editCallback={refreshTemplateList}
        title="Templates"
        additionalActionMenu={actionMenu()}
      />
    </Container>
  );
}
