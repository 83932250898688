import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Divider from "@mui/material/Divider";
import { useEffect, useState } from "react";
import { Api } from "./../../helper/Api";
import { useSnackbar } from "notistack";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import RSSelectInput from "../../components/inputs/RSSelectInput";
import useAppMessage from "../../helper/MobileApp";

export function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const appMessenger = useAppMessage();

  useEffect(() => {
    appMessenger({ messageType: "loggedOut" });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      entity: {
        name: name,
        email: email,
        phone: phone,
        street: street,
        zip_code: zipCode,
        state: state,
        country: country,
      },
      user: {
        name: name,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
      },
    };
    Api.post(
      "/registrations",
      formData,
      () => {
        setShowSuccessBanner(true);
      },
      (errorData) => {
        enqueueSnackbar(errorData.message, { variant: "error" });
      },
    );
  };

  const registerForm = () => {
    return (
      <Box
        sx={{
          maxWidth: 600,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item mobile={12}>
              <TextField
                name="name"
                required
                fullWidth
                label="Business / Personal Name"
                autoFocus
                onChange={(event) => setName(event.target.value)}
                value={name}
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                required
                fullWidth
                label="Email Address"
                name="email"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                fullWidth
                label="Contact Number"
                name="phone"
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
              />
            </Grid>
            <Grid item tablet={6} mobile={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(event) => setPassword(event.target.value)}
                value={password}
              />
            </Grid>
            <Grid item tablet={6} mobile={12}>
              <TextField
                required
                fullWidth
                name="password_confirmation"
                label="Password Confirmation"
                type="password"
                onChange={(event) =>
                  setPasswordConfirmation(event.target.value)
                }
                value={passwordConfirmation}
              />
            </Grid>
            <Grid item mobile={12}>
              <Divider variant="fullWidth" />
            </Grid>

            <Grid item mobile={12}>
              <TextField
                fullWidth
                name="street"
                label="Street"
                onChange={(event) => setStreet(event.target.value)}
                value={street}
              />
            </Grid>
            <Grid item tablet={6} mobile={12}>
              <TextField
                fullWidth
                name="city"
                label="City"
                onChange={(event) => setCity(event.target.value)}
                value={city}
              />
            </Grid>
            <Grid item tablet={6} mobile={12}>
              <TextField
                fullWidth
                name="state"
                label="State"
                onChange={(event) => setState(event.target.value)}
                value={state}
              />
            </Grid>
            <Grid item tablet={6} mobile={12}>
              <RSSelectInput
                name="country"
                label="Country"
                onChange={(_filed, countryCode) => {
                  setCountry(countryCode);
                }}
                defaultValue="IND"
                optionEndpoint="/geographies/countries"
              />
            </Grid>
            <Grid item tablet={6} mobile={12}>
              <TextField
                fullWidth
                name="zip_code"
                label="Zip Code"
                onChange={(event) => setZipCode(event.target.value)}
                value={zipCode}
              />
            </Grid>
            <Grid item mobile={12}>
              <Typography>
                By clicking sign up, you are consenting to our{" "}
                <Link href="/privacy_policy" variant="body2">
                  Privacy Policy
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Log in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const successBanner = () => {
    return (
      <Container
        sx={{ textAlign: "center", verticalAlign: "center", marginTop: 10 }}
      >
        <div style={{ fontSize: 100 }}>
          <CardGiftcardIcon fontSize="inherit" color="primary" />
        </div>
        <h2 style={{ margin: 0, color: "#0097a7" }}>Congratulations!</h2>
        <p>
          Your new account request has been created. For the account activation,
          our account specialists will contact you.
        </p>
        <Link href="/login" variant="body2">
          Back to login
        </Link>
      </Container>
    );
  };

  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      {showSuccessBanner ? successBanner() : registerForm()}
    </Box>
  );
}
