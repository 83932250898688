import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ListHeader from "../../components/common/ListHeader";
import ListTable from "../../components/common/ListTable";
import { Container } from "@mui/material";
import { formFields as taxClassFormFields } from "../settings/taxes/TaxClassList";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";

const formFields = [
  {
    label: "Name",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "name",
  },
  {
    label: "Customer Tax Class",
    defaultValue: "",
    required: true,
    fieldType: "autocomplete",
    fieldName: "tax_class_id",
    optionEndpoint: "/autocomplete?object=TaxClass&class_type=customer",
    addFormFields: taxClassFormFields,
    labelField: "tax_class_name",
    addTitle: "Tax Class",
    addEndpoint: "/settings/tax_classes",
    document: "TaxClass",
    multiple: false,
  },
];

export default function CustomerGroupList() {
  const [customerGroups, setCustomerGroups] = useState([]);
  const refreshCustomerGroups = () => {
    Api.get("/customer_groups", {}, setCustomerGroups);
  };

  useEffect(() => {
    refreshCustomerGroups();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Customers", link: "/customers" },
          { label: "Customer Groups", link: "/customer_groups" },
        ]}
      />
      <ListHeader
        title="Customer Groups"
        addCallback={refreshCustomerGroups}
        formFields={formFields}
        endpoint="/customer_groups"
        variant="h1"
      />
      <ListTable
        fields={formFields}
        rows={customerGroups}
        editEndpoint="/customer_groups/{id}"
        editCallback={refreshCustomerGroups}
        deleteEndpoint="/customer_groups/{id}"
        deleteCallback={refreshCustomerGroups}
        showEndpoint="/customer_groups/{id}"
        title="Customer Groups"
        hiddenActions={["view"]}
      />
    </Container>
  );
}
