import { Container } from "@mui/material";
import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListHeader from "../../../components/common/ListHeader";
import ListTable from "../../../components/common/ListTable";
import { Api } from "../../../helper/Api";
import { useEffect, useState } from "react";

const formFields = [
  {
    label: "Name",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "name",
  },
  {
    label: "Email",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "email",
  },
  {
    label: "Password",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "password",
    isPassword: true,
    hideInList: true,
  },
  {
    label: "Password Confirmation",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "password_confirmation",
    isPassword: true,
    hideInList: true,
  },
];

export default function UserList() {
  const [users, setUsers] = useState([]);
  const refreshUsersList = () => {
    Api.get("/settings/users", {}, setUsers);
  };

  useEffect(() => {
    refreshUsersList();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Users", link: "#" },
        ]}
      />
      <ListHeader
        title="Users"
        addCallback={refreshUsersList}
        formFields={formFields}
        endpoint="/settings/users"
        variant="h1"
        document="User"
      />
      <ListTable
        fields={formFields}
        rows={users}
        editEndpoint="/settings/users/{id}"
        deleteEndpoint="/settings/users/{id}"
        deleteCallback={refreshUsersList}
        hiddenActions={["view"]}
        editCallback={refreshUsersList}
        title="Users"
      />
    </Container>
  );
}
