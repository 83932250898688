import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import ListTable from "./ListTable";

const formFields = [
  {
    label: "Endpoint",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "endpoint",
  },
  {
    label: "Request",
    required: true,
    fieldType: "text",
    fieldName: "request",
  },
  {
    label: "Response",
    required: true,
    fieldType: "text",
    fieldName: "response",
  },
  {
    label: "Status",
    required: true,
    fieldType: "text",
    fieldName: "status",
  },
];
export default function WebhookLogListDialog(props) {
  const [webhookLogs, setWebhookLogs] = useState([]);
  useEffect(() => {
    if (props.documentId === 0) {
      return;
    }
    Api.get(
      "/webhook_logs",
      {
        document: props.document,
        document_id: props.documentId,
      },
      setWebhookLogs,
    );
  }, [props.documentId]);

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Webhook Event Logs</DialogTitle>
      <DialogContent>
        <ListTable fields={formFields} rows={webhookLogs} hideActions={true} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

WebhookLogListDialog.propTypes = {
  document: PropTypes.string,
  documentId: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
