import { useLocation, useParams } from "react-router-dom";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import { Box, Container } from "@mui/material";
import { useEffect, useRef } from "react";

export default function PreviewInvoice() {
  const iframeRef = useRef(null);
  const { id, templateId } = useParams();
  const iframeUrl = `${
    process.env.API_BASE_URL
  }/invoices/${id}/preview_html?template_id=${templateId}&jwt_token=${localStorage.getItem(
    "access_token"
  )}`;
  const location = useLocation();

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.addEventListener("load", () => {
        if (location.pathname.includes("print")) {
          iframeRef.current.contentWindow.postMessage("print", "*");
        }
      });
    }
  }, [iframeRef]);

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Invoices", link: "/invoices" },
          { label: id, link: `/invoices/${id}` },
          { label: "Preview", link: "" },
        ]}
      />
      <ShowPageTitle title={`Preview ${id}`} />
      <Box sx={{ mt: 3, width: "100%", typography: "body1", height: "100vh" }}>
        <iframe
          src={iframeUrl}
          ref={iframeRef}
          width="100%"
          style={{ border: "none", height: "100%" }}
        />
      </Box>
    </Container>
  );
}
