import { Box } from "@mui/material";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import ListTable from "../../components/common/ListTable";
import TabContext from "@mui/lab/TabContext";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Api } from "../../helper/Api";

export default function PurchaseOrderAssociation(props) {
  const [purchaseOrderLines, setPurchaseOrderLines] = useState([]);
  const purchaseOrderLineFields = useSelector(
    (state) => state.metadata["PurchaseOrderLine"]
  );

  const refreshPurchaseOrderLines = () => {
    Api.get(
      `/purchase_orders/${props.purchaseOrderId}/purchase_order_lines`,
      {},
      setPurchaseOrderLines
    );
  };
  useEffect(refreshPurchaseOrderLines, []);

  return (
    <Box sx={{ mt: 3, width: "100%", typography: "body1" }}>
      <TabContext value="purchaseOrderLines">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList aria-label="Associations">
            <Tab label="Purchase Order Lines" value="purchaseOrderLines" />
          </TabList>
        </Box>
        <TabPanel value="purchaseOrderLines">
          <ShowPageTitle title="Purchase Order Lines" variant="h6" />
          <ListTable
            fields={purchaseOrderLineFields}
            rows={purchaseOrderLines}
            title="Invoice Lines"
            document="InvoiceLine"
            hideActions={true}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

PurchaseOrderAssociation.propTypes = {
  purchaseOrderId: PropTypes.string,
};
