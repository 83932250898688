import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ShowPageTitle from "../../../components/common/ShowPageTitle";
import { Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import ConfirmationDialogue from "../../../components/common/ConfirmationDialogue";
import { Api } from "../../../helper/Api";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export default function DeleteAccount() {
  const [openDialogue, setOpenDialogue] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const confirmationCallback = () => {
    Api.post("/settings/entities/mark_for_deletion", {}, () => {
      navigate("/login", { replace: false });
      enqueueSnackbar("Account has been marked for removal.", {
        variant: "success",
      });
    });
  };
  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Account", link: "#" },
          { label: "Delete", link: "#" },
        ]}
      />
      <ShowPageTitle title="Delete Account" />
      <Typography variant="subtitle1">
        Please click on below "Delete Account" button to delete your account.
        Your access to this account will be disabled immediately and all of your
        belonging data on our servers will be erased permanently.
      </Typography>
      <br />
      <Button
        variant="outlined"
        color="error"
        onClick={() => {
          setOpenDialogue(true);
        }}
      >
        Delete Account
      </Button>
      <ConfirmationDialogue
        open={openDialogue}
        confirmationCallback={confirmationCallback}
        cancelCallback={() => {
          setOpenDialogue(false);
        }}
        description="Are you sure to delete your account ? All of your business data will be deleted from our servers."
      />
    </Container>
  );
}
