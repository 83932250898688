import { useSelector } from "react-redux";

export function useCurrencySymbol() {
  return useSelector((state) => {
    const defaultCurrency = state.generalSettings.currency;
    if (defaultCurrency) {
      return defaultCurrency.symbol;
    } else {
      return "$";
    }
  });
}

export function useCurrencyDisplay() {
  const currencySymbol = useCurrencySymbol();
  return getDisplayCurrency(currencySymbol);
}

export function getDisplayCurrency(currencySymbol) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const displayCurrency = (amount) => {
    if (!amount) {
      amount = 0;
    }
    return `${currencySymbol}${formatter.format(parseFloat(amount))}`;
  };
  return displayCurrency;
}
