import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ListHeader from "../../components/common/ListHeader";
import ListTable from "../../components/common/ListTable";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";
import { useSelector } from "react-redux";

export default function PaymentList() {
  const [payments, setPayments] = useState([]);
  const paymentFields = useSelector((state) => state.metadata["Payment"]);

  const refreshPaymentList = (params = {}) => {
    Api.get("/payments", params, setPayments);
  };

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb menuStack={[{ label: "Payments", link: "/payments" }]} />
      <ListHeader
        title="Payments"
        addCallback={refreshPaymentList}
        formFields={paymentFields}
        endpoint="/payments"
        variant="h1"
        filterCallback={refreshPaymentList}
        document="Payment"
      />
      <ListTable
        fields={paymentFields}
        rows={payments}
        editEndpoint="/payments/{id}"
        editCallback={refreshPaymentList}
        deleteEndpoint="/payments/{id}"
        deleteCallback={refreshPaymentList}
        showEndpoint="/payments/{id}"
        document="Payment"
      />
    </Container>
  );
}
