import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import {
  Avatar,
  Collapse,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { ExpandLess, ExpandMore, Logout } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";
import useFeatureEnabled from "../helper/Feature";
import { navItems } from "../helper/navItems";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const drawerWidth = 240;

const renderNavItemsList = (navs, handleDrawerToggle) => {
  const featureEnabled = useFeatureEnabled();
  return (
    <List>
      {navs.map((item) =>
        featureEnabled(item.feature) ? (
          <ListButton
            key={`list-button-${item.label}`}
            item={item}
            handleDrawerToggle={handleDrawerToggle}
          />
        ) : (
          ""
        ),
      )}
    </List>
  );
};

function ListButton(props) {
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const hasSubMenu = props.item.subItems && props.item.subItems.length > 0;

  const renderCollapsedMenu = (open, subItems, handleDrawerToggle) => {
    return (
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ ml: 1 }}>
        {renderNavItemsList(subItems, handleDrawerToggle)}
      </Collapse>
    );
  };

  return (
    <>
      <ListItemButton
        onClick={(event) => {
          event.preventDefault();
          if (props.item.path) {
            navigate(props.item.path, { replace: false });
            props.handleDrawerToggle();
          }
          setOpenSubMenu(!openSubMenu);
        }}
        sx={{ textAlign: "center" }}
      >
        <ListItemIcon>
          {props.item.icon ? props.item.icon : <ListIcon />}
        </ListItemIcon>
        <ListItemText sx={{ textAlign: "left", color: "#009688" }}>
          {props.item.label}
        </ListItemText>
        {hasSubMenu ? openSubMenu ? <ExpandLess /> : <ExpandMore /> : ""}
      </ListItemButton>
      {hasSubMenu
        ? renderCollapsedMenu(
            openSubMenu,
            props.item.subItems,
            props.handleDrawerToggle,
          )
        : ""}
    </>
  );
}

ListButton.propTypes = {
  item: PropTypes.object,
  handleDrawerToggle: PropTypes.func,
};

function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const accountMenuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => {
    return state.loggedInUser ? state.loggedInUser : {};
  });
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutUser = (event) => {
    localStorage.removeItem("access_token");
    window.location.href = "/login";
  };
  const navigateToResetPassword = (event) => {
    navigate("/reset_password", { replace: false });
  };
  return (
    <>
      <Tooltip title="Account">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ height: "100%" }}
          aria-controls={accountMenuOpen ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={accountMenuOpen ? "true" : undefined}
        >
          <Avatar variant="circular"></Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={accountMenuOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Typography sx={{ textAlign: "center", paddingX: 1 }}>
          {loggedInUser.name}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            paddingX: 1,
            paddingBottom: 1,
            fontSize: "12px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {loggedInUser.email}
        </Typography>
        <MenuItem onClick={navigateToResetPassword}>
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          Reset Password
        </MenuItem>
        <MenuItem onClick={logoutUser}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default function ClippedHeader() {
  const [modifiedNavItems, setModifiedNavItems] = useState([]);
  const matches = useMediaQuery("(min-width:600px)");
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const customObjects = useSelector((state) => {
    return state.customObjects || [];
  });

  useEffect(() => {
    setModifiedNavItems(
      navItems.map((navItem) => {
        if (navItem.customObjectMenu) {
          const newNavItem = Object.assign(navItem, {});
          newNavItem.subItems = customObjects.map((customObject) => {
            return {
              label: customObject.description,
              path: `/custom_objects/${customObject.name}`,
              icon: <AutoAwesomeIcon />,
            };
          });
          return newNavItem;
        }
        return navItem;
      }),
    );
  }, [customObjects]);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Grid container>
          <Grid mobile={8} tablet={8}>
            <Toolbar>
              {matches ? (
                ""
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              )}

              <Typography variant="h6" noWrap component="div">
                RASEETH
              </Typography>
            </Toolbar>
          </Grid>
          <Grid mobile={4} tablet={4} sx={{ textAlign: "right", paddingX: 1 }}>
            <AccountMenu />
          </Grid>
        </Grid>
      </AppBar>
      {matches ? (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              zIndex: 0,
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            {renderNavItemsList(modifiedNavItems, () => {})}
          </Box>
        </Drawer>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              paddingTop: "50px",
            },
          }}
        >
          {renderNavItemsList(modifiedNavItems, handleDrawerToggle)}
        </Drawer>
      )}
    </div>
  );
}
