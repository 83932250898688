import { Api } from "../../helper/Api";
import { useState, useEffect } from "react";
import Layout from "../Layout";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Divider from "@mui/material/Divider";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import useAppMessage from "../../helper/MobileApp";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const accessToken = urlParams.get("access_token");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const appMessenger = useAppMessage();

  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const checkUserLoggedIn = () => {
    Api.get(
      "/sessions/logged_in_user",
      {},
      (responseData) => {
        setIsLoggedIn(true);
      },
      () => {
        appMessenger({ messageType: "loggedOut" });
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    Api.post(
      accessToken
        ? `/sessions/reset_password?access_token=${accessToken}`
        : "/sessions/reset_password",
      { password: password, password_confirmation: passwordConfirmation },
      () => {
        enqueueSnackbar("You have successfully changed your password", {
          variant: "success",
        });
        navigate("/login", { replace: true });
      },
      (errorData) => {
        enqueueSnackbar(errorData.message, { variant: "error" });
      }
    );
  };

  const resetPasswordBody = () => {
    return (
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Box
          sx={{
            maxWidth: 600,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 3 }}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item tablet={6} mobile={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                />
              </Grid>
              <Grid item tablet={6} mobile={12}>
                <TextField
                  required
                  fullWidth
                  name="password_confirmation"
                  label="Password Confirmation"
                  type="password"
                  onChange={(event) =>
                    setPasswordConfirmation(event.target.value)
                  }
                  value={passwordConfirmation}
                />
              </Grid>
              <Grid item mobile={12}>
                <Divider variant="fullWidth" />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  if (isLoggedIn) {
    return (
      <Layout>
        <Container maxWidth="xl" style={{ padding: 0 }}>
          <RSBreadcrumb menuStack={[{ label: "Reset Password", link: "" }]} />
          {resetPasswordBody()}
        </Container>
      </Layout>
    );
  } else {
    return resetPasswordBody();
  }
}
