import { Box, Paper } from "@mui/material";
import PropTypes from "prop-types";

export default function Card(props) {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ textAlign: "center", overflow: "auto" }}>
        <h3 style={{ fontSize: "34px" }}>{props.title}</h3>
        <h5>{props.subTitle}</h5>
      </Box>
    </Paper>
  );
}

Card.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.string,
};
