import { useEffect, useState } from "react";
import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListHeaderReadOnly from "../../../components/common/ListHeaderReadOnly";
import ListTable from "../../../components/common/ListTable";
import { Container } from "@mui/material";
import { Api } from "../../../helper/Api";

const formFields = [
  {
    label: "Document",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "document",
  },
  {
    label: "Total Extended Fields",
    defaultValue: "",
    required: false,
    fieldType: "text",
    fieldName: "total_fields",
  },
];

export default function ExtendObjectsList() {
  const [extendObjectList, setExtendObjectList] = useState([]);

  const refreshExtendObjectList = () => {
    Api.get("/settings/extend_objects", {}, setExtendObjectList);
  };

  useEffect(() => {
    refreshExtendObjectList();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Extend Objects", link: "#" },
        ]}
      />
      <ListHeaderReadOnly title="Extend Objects" variant="h1" />
      <ListTable
        fields={formFields}
        rows={extendObjectList}
        editEndpoint="/settings/extend_objects/{id}"
        showEndpoint="/settings/extend_objects/{id}"
        hiddenActions={["edit", "delete"]}
        editCallback={refreshExtendObjectList}
      />
    </Container>
  );
}
