import PropTypes from "prop-types";
import { Grid, TextField } from "@mui/material";
import { useState } from "react";

export default function RSTextRangeField(props) {
  let firstValue = "";
  let secondValue = "";
  if (props.defaultValue) {
    firstValue = props.defaultValue[0];
    secondValue = props.defaultValue[1];
  }
  const [value, setValue] = useState([firstValue, secondValue]);

  return (
    <Grid container>
      <Grid mobile={5} tablet={5}>
        <TextField
          label="Start"
          name={`${props.fieldName}[]`}
          variant="outlined"
          defaultValue={value[0]}
          fullWidth
        />
      </Grid>
      <Grid mobile={2} tablet={2} textAlign="center">
        To
      </Grid>
      <Grid mobile={5} tablet={5}>
        <TextField
          label="End"
          name={`${props.fieldName}[]`}
          variant="outlined"
          defaultValue={value[1]}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

RSTextRangeField.propTypes = {
  defaultValue: PropTypes.any,
  fieldName: PropTypes.string,
};
