import { FormControl, FormControlLabel, Switch } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export default function RSToggleSwitch(props) {
  const [value, setValue] = useState(false);
  useEffect(() => {
    if (props.defaultValue) {
      setValue(true);
    } else {
      setValue(false);
    }
    if (props.onChange) {
      props.onChange(props.fieldName, props.defaultValue === true);
    }
  }, [props.defaultValue]);

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={
          <Switch
            name={props.fieldName}
            checked={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              if (props.onChange) {
                props.onChange(props.fieldName, newValue);
              }
            }}
          />
        }
        label={props.label}
      />
    </FormControl>
  );
}

RSToggleSwitch.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
};
