import { Container } from "@mui/material";
import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListHeader from "../../../components/common/ListHeader";
import ListTable from "../../../components/common/ListTable";
import { useEffect, useState } from "react";
import { Api } from "../../../helper/Api";
import { useSelector } from "react-redux";

export default function UOMList() {
  const [unitOfMeasures, setUnitOfMeasures] = useState([]);

  useEffect(() => {
    refreshUOMList();
  }, []);

  const formFields = useSelector((state) => state.metadata["UnitOfMeasure"]);

  const refreshUOMList = () => {
    Api.get("/settings/unit_of_measures", {}, setUnitOfMeasures);
  };
  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Unit Of Measures", link: "#" },
        ]}
      />
      <ListHeader
        title="Unit Of Measure"
        addCallback={refreshUOMList}
        formFields={formFields}
        endpoint="/settings/unit_of_measures"
        variant="h1"
        document="UnitOfMeasure"
      />
      <ListTable
        fields={formFields}
        rows={unitOfMeasures}
        editEndpoint="/settings/unit_of_measures/{id}"
        deleteEndpoint="/settings/unit_of_measures/{id}"
        deleteCallback={refreshUOMList}
        hiddenActions={["view"]}
        editCallback={refreshUOMList}
        title="Unit Of Measure"
      />
    </Container>
  );
}
