import PropTypes from "prop-types";
import { Box, Button, Chip, Typography } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popper from "@mui/material/Popper";
import { useEffect, useState } from "react";
import FilterRow from "./FilterRow";

export default function Filter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterFields, setFilterFields] = useState([]);
  const [filterCount, setFilterCount] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState(
    props.selectedFilters ? props.selectedFilters : [],
  );

  useEffect(() => {
    setFilterFields(
      props.fields.map((field) => {
        return {
          id: field.fieldName,
          label: field.label,
          fieldType: field.isNumber ? "number" : field.fieldType,
        };
      }),
    );
  }, [props.fields]);

  useEffect(() => {
    const filterCount = selectedFilters.length;
    if (filterCount === 0) {
      setFilterCount(1);
    } else {
      setFilterCount(filterCount);
    }
    // Refresh list page with latest filters
    props.filterCallback({ filters: selectedFilters });
  }, [selectedFilters]);

  const openFilter = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popper" : undefined;

  const applyFilter = (event) => {
    event.preventDefault();
    setFilterCount(1);
    // Close popper
    setAnchorEl(null);

    // Building filter parameters
    const formData = new FormData(event.currentTarget);
    const filters = [];
    let filter = { field: "", values: [], operator: "" };
    let addChild = false;
    for (const [fieldName, fieldValue] of formData.entries()) {
      if (fieldName === "filters[][field]") {
        filter = { field: "", values: [], operator: "" };
        if (fieldValue) {
          filter.field = fieldValue;
          filters.push(filter);
          addChild = true;
        }
      } else if (fieldName === "filters[][operator]" && addChild) {
        filter.operator = fieldValue;
      } else if (fieldName === "filters[][values][]" && addChild) {
        filter.values.push(fieldValue);
      }
    }
    setSelectedFilters(filters);
  };

  const formId = "filter-form";

  const renderFilterRow = (idx, filterFields, hide) => {
    let defaultFieldValue = "";
    let defaultOperatorValue = "";
    let defaultValues = [];
    const selectedFilter = selectedFilters[idx];
    if (selectedFilter) {
      defaultFieldValue = selectedFilter.field;
      defaultOperatorValue = selectedFilter.operator;
      defaultValues = selectedFilter.values;
    }
    return (
      <FilterRow
        key={`filter-row-${idx}`}
        filterFields={filterFields}
        hide={hide}
        rowKey={`filter-row-key-${idx}`}
        defaultFieldValue={defaultFieldValue}
        defaultOperatorValue={defaultOperatorValue}
        defaultValues={defaultValues}
      />
    );
  };

  const renderFilterFieldValueChip = (idx, field, operator, values) => {
    let valueLabel = values.join(" OR ");

    if (operator === "between") {
      const betweenValues = [];
      const valuesLength = values.length;
      for (let ind = 0; ind < valuesLength / 2; ind++) {
        const actualIndex = ind * 2;
        betweenValues.push(
          `${values[actualIndex]} - ${values[actualIndex + 1]}`,
        );
      }
      valueLabel = betweenValues.join(" OR ");
    }
    const label = `${field.replaceAll("_", " ")} ${operator.replaceAll(
      "_",
      " ",
    )} ${valueLabel}`;
    return (
      <Chip
        key={`filter-field-chip-${idx}`}
        label={label}
        variant="outlined"
        onDelete={() => {
          setSelectedFilters(
            selectedFilters.filter((value, index) => index !== idx),
          );
        }}
        sx={{ ml: 1, textTransform: "capitalize" }}
      />
    );
  };

  return (
    <>
      <Chip
        aria-describedby={id}
        label="Filter"
        clickable
        icon={<FilterListIcon />}
        onClick={openFilter}
      />
      {selectedFilters.map((filter, idx) => {
        return renderFilterFieldValueChip(
          idx,
          filter.field,
          filter.operator,
          filter.values,
        );
      })}
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box
          component="form"
          sx={{
            border: "1px solid rgba(224, 224, 224, 1)",
            p: 1,
            width: {
              mobile: 320,
              tablet: 320,
            },
            bgcolor: "background.paper",
          }}
          id={formId}
          onSubmit={applyFilter}
        >
          <Typography variant="subtitle1">Filter {props.title}</Typography>
          {Array.from(Array(filterCount).keys()).map((idx) => {
            const hide = idx !== filterCount - 1;
            return renderFilterRow(idx, filterFields, hide);
          })}

          <Box sx={{ height: 50, mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ float: "right" }}
              form={formId}
              type="submit"
            >
              Apply
            </Button>
            <Button
              variant="text"
              color="primary"
              sx={{ float: "right", mr: 1 }}
              onClick={openFilter}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ float: "left" }}
              onClick={() => {
                setFilterCount(filterCount + 1);
              }}
            >
              AND
            </Button>
          </Box>
        </Box>
      </Popper>
    </>
  );
}

Filter.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  filterCallback: PropTypes.func,
  selectedFilters: PropTypes.arrayOf(PropTypes.object),
};
