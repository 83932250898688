import { useSelector } from "react-redux";

export const documentIdentifier = {
  label: "ID",
  defaultValue: "",
  required: true,
  fieldType: "text",
  fieldName: "document_identifier",
};

export function useAutoId(document) {
  const documentIdSetting = useSelector((state) => {
    return state.documentIdSettings.find((doc) => doc.document === document);
  });
  if (documentIdSetting) {
    return !documentIdSetting.is_manual;
  }

  return true;
}
