import { Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import ListTable from "../../components/common/ListTable";
import { useSelector } from "react-redux";
import { useState } from "react";
import ListHeader from "../../components/common/ListHeader";
import { Api } from "../../helper/Api";
import PropTypes from "prop-types";

export default function ProductAssociation(props) {
  const inventoryFields = useSelector((state) => state.metadata["Inventory"]);
  const [inventories, setInventories] = useState([]);

  const refreshInventories = (params = {}) => {
    Api.get(
      `/inventories?product_id=${props.productId}`,
      params,
      setInventories
    );
  };

  return (
    <Box sx={{ mt: 3, width: "100%", typography: "body1" }}>
      <TabContext value="inventories">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList aria-label="Associations">
            <Tab label="Inventories" value="inventories" />
          </TabList>
        </Box>
        <TabPanel value="inventories">
          <ListHeader
            title="Inventories"
            addCallback={refreshInventories}
            formFields={inventoryFields}
            filterCallback={refreshInventories}
            endpoint="/inventories"
            variant="h1"
            document="Inventory"
          />
          <ListTable
            fields={inventoryFields}
            rows={inventories}
            title="Inventories"
            hideActions={true}
            showEndpoint="/invoices/{id}"
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

ProductAssociation.propTypes = {
  productId: PropTypes.string,
};
