import { Box, Container } from "@mui/material";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import PaymentDashboard from "./PaymentDashboard";
import InvoiceDashboard from "./InvoiceDashboard";

export default function Home() {
  const [activeTab, setActiveTab] = useState("payments");
  return (
    <Container maxWidth="xl" sx={{ mb: 4, p: 0 }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(event, newValue) => {
              setActiveTab(newValue);
            }}
            aria-label="Dashboard"
            textColor="secondary"
          >
            <Tab label="Payments" value="payments" />
            <Tab label="Invoices" value="invoices" />
          </TabList>
        </Box>
        <TabPanel value="payments">
          <PaymentDashboard />
        </TabPanel>
        <TabPanel value="invoices">
          <InvoiceDashboard />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
