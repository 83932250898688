import { useEffect, useState } from "react";
import { Api } from "../../../helper/Api";
import { Container } from "@mui/material";
import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListHeader from "../../../components/common/ListHeader";
import ListTable from "../../../components/common/ListTable";
import { formFields as taxClassFormFields } from "./TaxClassList";
import { formFields as taxRateFormFields } from "./TaxRatesList";

const formFields = [
  {
    label: "Name",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "name",
  },
  {
    label: "Tax Rates",
    defaultValue: [],
    required: true,
    fieldType: "autocomplete",
    fieldName: "tax_rates",
    optionEndpoint: "/autocomplete?object=TaxRate",
    addFormFields: taxRateFormFields,
    labelField: "tax_rates_label",
    addTitle: "Tax Rates",
    addEndpoint: "/settings/tax_rates",
    document: "TaxClass",
    multiple: true,
  },
  {
    label: "Customer Tax Class",
    defaultValue: [],
    required: true,
    fieldType: "autocomplete",
    fieldName: "customer_tax_classes",
    optionEndpoint: "/autocomplete?object=TaxClass&class_type=customer",
    addFormFields: taxClassFormFields,
    labelField: "customer_tax_classes_label",
    addTitle: "Tax Classes",
    addEndpoint: "/settings/tax_classes",
    document: "TaxClass",
    multiple: true,
  },
  {
    label: "Product Tax Class",
    defaultValue: [],
    required: true,
    fieldType: "autocomplete",
    fieldName: "product_tax_classes",
    optionEndpoint: "/autocomplete?object=TaxClass&class_type=product",
    addFormFields: taxClassFormFields,
    labelField: "product_tax_classes_label",
    addTitle: "Tax Classes",
    addEndpoint: "/settings/tax_classes",
    document: "TaxClass",
    multiple: true,
  },
  {
    label: "Priority",
    defaultValue: "0",
    required: false,
    fieldType: "text",
    fieldName: "priority",
  },
  {
    label: "Exclusive",
    defaultValue: false,
    required: false,
    fieldType: "toggle",
    fieldName: "exclusive",
  },
];

export default function TaxRulesList() {
  const [taxRules, setTaxRules] = useState([]);

  const refreshTaxRules = () => {
    Api.get("/settings/tax_rules", {}, setTaxRules);
  };

  useEffect(() => {
    refreshTaxRules();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "" },
          { label: "Tax", link: "" },
          { label: "Rules", link: "" },
        ]}
      />
      <ListHeader
        title="Tax Rules"
        addCallback={refreshTaxRules}
        formFields={formFields}
        endpoint="/settings/tax_rules"
        variant="h1"
        document="TaxRule"
      />
      <ListTable
        fields={formFields}
        rows={taxRules}
        editEndpoint="/settings/tax_rules/{id}"
        editCallback={refreshTaxRules}
        deleteEndpoint="/settings/tax_rules/{id}"
        deleteCallback={refreshTaxRules}
        showEndpoint="/settings/tax_rules/{id}"
        document="TaxRule"
        title="Tax Rule"
        hiddenActions={["view"]}
      />
    </Container>
  );
}
