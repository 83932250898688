import { useSelector } from "react-redux";

export default function useFeatureEnabled() {
  const features = useSelector((payload) => payload.appFeatures);
  const featureEnabled = (featureLabel) => {
    if (!featureLabel) {
      return true;
    }

    const feature = features.find((ft) => ft.feature === featureLabel);
    return feature ? feature.enabled : false;
  };
  return featureEnabled;
}
