import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useState } from "react";
import { Grid } from "@mui/material";
import RSDateField from "./RSDateField";

export default function RSDateRangeField(props) {
  let dateFrom = dayjs();
  let dateTo = dayjs();
  if (props.defaultValue) {
    dateFrom = dayjs(props.defaultValue[0]);
    dateTo = dayjs(props.defaultValue[1]);
  }
  const [value, setValue] = useState([dateFrom, dateTo]);

  return (
    <Grid container>
      <Grid mobile={5} tablet={5}>
        <RSDateField
          fieldName={`${props.fieldName}[]`}
          label="Start"
          defaultValue={value[0]}
        />
      </Grid>
      <Grid mobile={2} tablet={2} textAlign="center">
        To
      </Grid>
      <Grid mobile={5} tablet={5}>
        <RSDateField
          fieldName={`${props.fieldName}[]`}
          label="End"
          defaultValue={value[1]}
        />
      </Grid>
    </Grid>
  );
}
RSDateRangeField.propTypes = {
  fieldName: PropTypes.string,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
};
