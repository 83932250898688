import { Api } from "../Api";
import { mapContactList } from "../TransformHelper";

export const handleInvoiceFormChange = (
  fieldName,
  newValue,
  formFields,
  setFormFields
) => {
  if (fieldName === "customer_id" && newValue) {
    Api.get(`/customers/${newValue.id}/contacts`, {}, (contacts) => {
      setInvoiceBillingAddress(contacts, formFields, setFormFields);
    });
  }
  if (fieldName === "invoice_lines_attributes[][product_id]" && newValue) {
    Api.get(`/products/${newValue.id}`, {}, (product) => {
      setProductInfo(product, formFields, setFormFields, newValue);
    });
  }
};

const setInvoiceBillingAddress = (contacts, formFields, setFormFields) => {
  const newFormFields = formFields.slice();
  const addAddressFieldValues = (fieldName) => {
    const billingFieldIdx = formFields.findIndex(
      (field) => field.fieldName === fieldName
    );
    const availableValues = mapContactList(contacts);
    const billingField = Object.assign({}, formFields[billingFieldIdx]);
    billingField.availableValues = availableValues;
    if (availableValues.length > 0) {
      billingField.defaultValue = availableValues[0].id;
    }
    newFormFields[billingFieldIdx] = billingField;
  };
  addAddressFieldValues("billing_address_id");
  addAddressFieldValues("shipping_address_id");

  setFormFields(newFormFields);
};

const setProductInfo = (product, formFields, setFormFields, newValue) => {
  const newFormFields = formFields.slice();

  const nameFieldIdx = formFields.findIndex(
    (field) => field.fieldName === "invoice_lines_attributes[][product_name]"
  );
  const nameField = Object.assign({}, formFields[nameFieldIdx]);
  nameField.defaultValue = product.name;
  newFormFields[nameFieldIdx] = nameField;

  const descriptionFieldIdx = formFields.findIndex(
    (field) =>
      field.fieldName === "invoice_lines_attributes[][product_description]"
  );
  const descriptionField = Object.assign({}, formFields[descriptionFieldIdx]);
  descriptionField.defaultValue = product.description;
  newFormFields[descriptionFieldIdx] = descriptionField;

  const priceFieldIdx = formFields.findIndex(
    (field) => field.fieldName === "invoice_lines_attributes[][price]"
  );
  const priceField = Object.assign({}, formFields[priceFieldIdx]);
  priceField.defaultValue = product.price;
  newFormFields[priceFieldIdx] = priceField;

  const productIdFieldIdx = formFields.findIndex(
    (field) => field.fieldName === "invoice_lines_attributes[][product_id]"
  );
  const productIdField = Object.assign({}, formFields[productIdFieldIdx]);
  productIdField.defaultValue = newValue;
  newFormFields[productIdFieldIdx] = productIdField;

  setFormFields(newFormFields);
};

export const setEditFormFields = (
  invoiceFields,
  invoice,
  setEditFormFieldsCallback,
  setEditFormOpened
) => {
  Api.get(
    `/invoices/${invoice.document_identifier}/invoice_lines`,
    {},
    (invoiceLines) => {
      const newInvoiceFields = invoiceFields.map((invoiceField) => {
        const newInvoiceField = Object.assign({}, invoiceField);
        if (newInvoiceField.fieldName === "customer_id") {
          newInvoiceField.disabled = true;
        }
        newInvoiceField.defaultValue = invoice[invoiceField.fieldName];
        return newInvoiceField;
      });

      const invoiceLineField = newInvoiceFields.find(
        (field) => field.fieldName === "invoice_lines"
      );
      invoiceLineField.formFields = invoiceLines.map((invoiceLine) => {
        return invoiceLineField.formFields.map((lineField) => {
          const newLineField = Object.assign({}, lineField);
          const fieldName = newLineField.fieldName.match(
            /invoice_lines_attributes\[\]\[(.*)\]/
          )[1];
          newLineField.defaultValue = invoiceLine[fieldName];
          return newLineField;
        });
      });

      const billingAddressField = newInvoiceFields.find(
        (field) => field.fieldName === "billing_address_id"
      );
      const shippingAddressField = newInvoiceFields.find(
        (field) => field.fieldName === "shipping_address_id"
      );
      Api.get(`/customers/${invoice.customer_id}/contacts`, {}, (contacts) => {
        billingAddressField.availableValues = mapContactList(contacts);
        shippingAddressField.availableValues = mapContactList(contacts);

        setEditFormFieldsCallback(newInvoiceFields);
        setEditFormOpened(true);
      });
    }
  );
};
