import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";
import ListHeader from "../../components/common/ListHeader";
import ListTable from "../../components/common/ListTable";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import { useSelector } from "react-redux";

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const productFields = useSelector((state) => state.metadata["Product"]);

  const getProducts = (params = {}) => {
    Api.get("/products", params, (data) => {
      setProducts(data);
    });
  };

  const refreshProductList = (data = {}) => {
    getProducts(data);
  };

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb menuStack={[{ label: "Products", link: "/products" }]} />
      <ListHeader
        title="Products"
        addCallback={refreshProductList}
        formFields={productFields}
        filterCallback={refreshProductList}
        endpoint="/products"
        variant="h1"
        document="Product"
      />
      <ListTable
        fields={productFields}
        rows={products}
        title="Products"
        editEndpoint="/products/{id}"
        editCallback={refreshProductList}
        deleteEndpoint="/products/{id}"
        deleteCallback={refreshProductList}
        showEndpoint="/products/{id}"
        document="Product"
      />
    </Container>
  );
}
