import {
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { Api } from "../../helper/Api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();

    Api.post(
      "/sessions/forgot_password",
      { email: email },
      () => {
        enqueueSnackbar(
          "You will get an email with the link to reset password if the email provided is in our system",
          {
            variant: "success",
          }
        );
        navigate("/login", { replace: true });
      },
      (errorData) => {
        enqueueSnackbar(errorData.message, { variant: "error" });
      }
    );
  };
  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item tablet={12} mobile={12}>
              <TextField
                required
                fullWidth
                name="email"
                label="Email"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
              />
            </Grid>
            <Grid item mobile={12}>
              <Divider variant="fullWidth" />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Log in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
