import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./pages/account/Login";
import { Register } from "./pages/account/Register";
import Layout from "./pages/Layout";
import Home from "./pages/dashboard/Home";
import ProductList from "./pages/products/ProductList";
import CustomerList from "./pages/customers/CustomerList";
import CustomerShow from "./pages/customers/CustomerShow";
import ProductShow from "./pages/products/ProductShow";
import ContactShow from "./pages/contacts/ContactShow";
import PaymentList from "./pages/payments/PaymentList";
import PaymentShow from "./pages/payments/PaymentShow";
import DocumentNumberingList from "./pages/settings/document_numbering/DocumentNumberingList";
import ExtendObjectsList from "./pages/settings/extend_objects/ExtendObjectsList";
import ExtendObjectShow from "./pages/settings/extend_objects/ExtendObjectShow";
import CompanyProfile from "./pages/settings/company/CompanyProfile";
import InvoiceList from "./pages/invoices/InvoiceList";
import InvoiceShow from "./pages/invoices/InvoiceShow";
import TemplateList from "./pages/settings/templates/TemplateList";
import PreviewInvoice from "./pages/invoices/PreviewInvoice";
import ResetPassword from "./pages/account/ResetPassword";
import ForgotPassword from "./pages/account/ForgotPassword";
import StatementList from "./pages/statements/StatementList";
import StatementShow from "./pages/statements/StatementShow";
import EntityCurrency from "./pages/settings/currency/EntityCurrency";
import UserList from "./pages/settings/users/UserList";
import TaxClassList from "./pages/settings/taxes/TaxClassList";
import TaxRulesList from "./pages/settings/taxes/TaxRulesList";
import TaxRatesList from "./pages/settings/taxes/TaxRatesList";
import CustomerGroupList from "./pages/customers/CustomerGroupList";
import UOMList from "./pages/settings/unit_of_measure/UOMList";
import InventoryLocation from "./pages/settings/inventory/InventoryLocation";
import InventoryList from "./pages/inventory/InventoryList";
import PrivacyPolicy from "./pages/account/PrivacyPolicy";
import DeleteAccount from "./pages/settings/account/DeleteAccount";
import PurchaseOrderList from "./pages/purchase_order/PurchaseOrderList";
import PurchaseOrderShow from "./pages/purchase_order/PurchaseOrderShow";
import CustomObjectsList from "./pages/settings/custom_objects/CustomObjectsList";
import CustomObjectsTable from "./pages/custom_objects/CustomObjectsTable";
import CustomObjectsShow from "./pages/custom_objects/CustomObjectsShow";
import WebhookList from "./pages/settings/webhook/WebhookList";

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:id" element={<ProductShow />} />
          <Route path="/inventories" element={<InventoryList />} />
          <Route path="/purchase_orders" element={<PurchaseOrderList />} />
          <Route
            path="/purchase_orders/:id"
            element={<PurchaseOrderShow />}
          ></Route>
          <Route path="/customers" element={<CustomerList />} />
          <Route path="/customers/:id" element={<CustomerShow />} />
          <Route
            path="/customers/:customerId/contacts/:id"
            element={<ContactShow />}
          />
          <Route path="/customer_groups" element={<CustomerGroupList />} />
          <Route path="/payments" element={<PaymentList />} />
          <Route path="/payments/:id" element={<PaymentShow />} />
          <Route path="/invoices" element={<InvoiceList />} />
          <Route path="/invoices/:id" element={<InvoiceShow />} />
          <Route
            path="/invoices/:id/preview/:templateId"
            element={<PreviewInvoice />}
          />
          <Route
            path="/invoices/:id/print/:templateId"
            element={<PreviewInvoice />}
          />
          <Route path="/statements" element={<StatementList />} />
          <Route path="/statements/:id" element={<StatementShow />} />
          <Route path="/statements/:id/print" element={<StatementShow />} />
          <Route
            path="/custom_objects/:customObject"
            element={<CustomObjectsTable />}
          />
          <Route
            path="/custom_objects/:customObject/:objectId"
            element={<CustomObjectsShow />}
          />
          <Route
            path="/settings/document_numbering"
            element={<DocumentNumberingList />}
          />
          <Route
            path="/settings/extend_objects"
            element={<ExtendObjectsList />}
          />
          <Route
            path="/settings/custom_objects"
            element={<CustomObjectsList />}
          />
          <Route
            path="/settings/extend_objects/:id"
            element={<ExtendObjectShow />}
          />
          <Route
            path="/settings/company_profile"
            element={<CompanyProfile />}
          />
          <Route path="/settings/users" element={<UserList />} />
          <Route path="/settings/templates" element={<TemplateList />} />
          <Route path="/settings/currencies" element={<EntityCurrency />} />
          <Route path="/settings/taxes/classes" element={<TaxClassList />} />
          <Route path="/settings/taxes/rules" element={<TaxRulesList />} />
          <Route path="/settings/taxes/rates" element={<TaxRatesList />} />
          <Route path="/settings/unit_of_measures" element={<UOMList />} />
          <Route
            path="/settings/inventory_locations"
            element={<InventoryLocation />}
          />
          <Route
            path="/settings/account/delete_account"
            element={<DeleteAccount />}
          />
          <Route path="/settings/webhooks" element={<WebhookList />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}
