import { useLocation, useParams } from "react-router-dom";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import { Box, Container } from "@mui/material";
import { useRef, useEffect } from "react";

export default function StatementShow() {
  const iframeRef = useRef();
  const { id } = useParams();
  const iframeUrl = `${
    process.env.API_BASE_URL
  }/statements/${id}/preview_html?jwt_token=${localStorage.getItem(
    "access_token"
  )}`;

  const location = useLocation();

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.addEventListener("load", () => {
        if (location.pathname.includes("print")) {
          iframeRef.current.contentWindow.postMessage("print", "*");
        }
      });
    }
  }, [iframeRef]);

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Statements", link: "/statements" },
          { label: id, link: `/statements/${id}` },
        ]}
      />
      <ShowPageTitle title={`Statement - ${id}`} />
      <Box sx={{ mt: 3, width: "100%", typography: "body1", height: "100vh" }}>
        <iframe
          src={iframeUrl}
          ref={iframeRef}
          width="100%"
          style={{ border: "none", height: "100%" }}
        />
      </Box>
    </Container>
  );
}
