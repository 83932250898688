import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";

export default function ListHeaderReadOnly(props) {
  return (
    <Grid container>
      <Grid mobile={12} tablet={12}>
        <Typography
          variant={props.variant}
          sx={{ fontSize: { tablet: "3rem", mobile: "2rem" } }}
          style={{ float: "left" }}
        >
          {props.title}
        </Typography>
      </Grid>
    </Grid>
  );
}
ListHeaderReadOnly.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
};
