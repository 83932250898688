import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RSAssociationFieldRow from "../common/RSAssociationFieldRow";

export default function RSAssociationField(props) {
  const [associationFields, setAssociationFields] = useState([]);
  const parentFields = useSelector(
    (state) => state.metadata[props.parentDocument],
  );

  useEffect(() => {
    if (Array.isArray(props.field.formFields[0])) {
      setAssociationFields(
        props.field.formFields.map((fields) =>
          fields.filter((field) => !field.displayOnly),
        ),
      );
    } else {
      setAssociationFields([
        props.field.formFields.filter((field) => !field.displayOnly),
      ]);
    }
  }, [props.field]);

  const addAnotherRow = () => {
    const metadata = parentFields.find(
      (field) => field.fieldName === props.field.fieldName,
    ).formFields;

    const firstRow = metadata.map((field) => {
      const newField = Object.assign({}, field);
      return newField;
    });
    setAssociationFields([...associationFields, firstRow]);
  };

  return (
    <Box
      sx={{ mt: 4, mb: 4, marginLeft: "10px", clear: "both" }}
      key={`field-${props.field.fieldName}`}
    >
      <Divider />
      <Typography gutterBottom variant="h6">
        {props.field.label}
      </Typography>

      {associationFields.map((associations, idx) => {
        return (
          <RSAssociationFieldRow
            associationFields={associations}
            rowIndex={idx}
            key={`RSAssociationFieldRow-${idx}`}
            hide={idx !== associationFields.length - 1}
            document={props.document}
          />
        );
      })}
      <div>
        <Button onClick={addAnotherRow} type="button">
          Add another
        </Button>
      </div>
    </Box>
  );
}

RSAssociationField.propTypes = {
  field: PropTypes.object,
  document: PropTypes.string,
  parentDocument: PropTypes.string,
  onFieldValueChange: PropTypes.func,
};
