import { useEffect, useState } from "react";
import { Api } from "../../../helper/Api";
import { Container } from "@mui/material";
import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListHeader from "../../../components/common/ListHeader";
import ListTable from "../../../components/common/ListTable";

export const formFields = [
  {
    label: "Identifier",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "identifier",
  },
  {
    label: "By Postal Range",
    defaultValue: false,
    required: true,
    fieldType: "toggle",
    fieldName: "is_postal_range",
  },
  {
    label: "Start Postal Code",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "postal_code_start",
    showOnly: [{ fieldName: "is_postal_range", values: [true] }],
  },
  {
    label: "End Postal Code",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "postal_code_end",
    showOnly: [{ fieldName: "is_postal_range", values: [true] }],
  },
  {
    label: "Postal Code Regex",
    defaultValue: "*",
    required: true,
    fieldType: "text",
    fieldName: "postal_code_regex",
    showOnly: [{ fieldName: "is_postal_range", values: [false, null] }],
  },
  {
    label: "State",
    defaultValue: "-",
    required: false,
    fieldType: "select",
    fieldName: "state",
    optionEndpoint: "/geographies/states?country_code=IND&include_star=true",
  },
  {
    label: "Country",
    defaultValue: "IND",
    required: false,
    fieldType: "select",
    fieldName: "country",
    optionEndpoint: "/geographies/countries",
  },
  {
    label: "Rate Type",
    defaultValue: "percentage",
    required: true,
    fieldType: "radio",
    fieldName: "rate_type",
    availableValues: ["percentage", "amount"],
  },
  {
    label: "Rate",
    defaultValue: "0",
    required: true,
    fieldType: "text",
    fieldName: "rate",
  },
];

export default function TaxRatesList() {
  const [taxRules, setTaxRates] = useState([]);

  const refreshTaxRates = () => {
    Api.get("/settings/tax_rates", {}, setTaxRates);
  };

  useEffect(() => {
    refreshTaxRates();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "" },
          { label: "Tax", link: "" },
          { label: "Rates", link: "" },
        ]}
      />
      <ListHeader
        title="Tax Rates"
        addCallback={refreshTaxRates}
        formFields={formFields}
        endpoint="/settings/tax_rates"
        variant="h1"
        document="TaxRate"
      />
      <ListTable
        fields={formFields}
        rows={taxRules}
        editEndpoint="/settings/tax_rates/{id}"
        editCallback={refreshTaxRates}
        deleteEndpoint="/settings/tax_rates/{id}"
        deleteCallback={refreshTaxRates}
        showEndpoint="/settings/tax_rates/{id}"
        document="TaxRate"
        title="Tax Rates"
        hiddenActions={["view"]}
      />
    </Container>
  );
}
