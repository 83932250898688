import { Container } from "@mui/material";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ListHeader from "../../components/common/ListHeader";
import { useSelector } from "react-redux";
import { useState } from "react";
import ListTable from "../../components/common/ListTable";
import { Api } from "../../helper/Api";
import { setEditFormFields } from "../../helper/fields/purchaseOrderForm";

export default function PurchaseOrderList() {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const formFields = useSelector((state) => state.metadata["PurchaseOrder"]);

  const refreshPurchaseOrders = (params) => {
    Api.get("/purchase_orders", params, setPurchaseOrders);
  };

  const updateEditFormFields = (
    purchaseOrder,
    setEditFormFieldsCallback,
    setEditFormOpened
  ) => {
    setEditFormFields(
      formFields,
      purchaseOrder,
      setEditFormFieldsCallback,
      setEditFormOpened
    );
  };

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb menuStack={[{ label: "Purchase Order", link: "#" }]} />
      <ListHeader
        title="Purchase Orders"
        addCallback={refreshPurchaseOrders}
        formFields={formFields}
        filterCallback={refreshPurchaseOrders}
        endpoint="/purchase_orders"
        variant="h1"
        document="PurchaseOrder"
      />
      <ListTable
        fields={formFields}
        rows={purchaseOrders}
        showEndpoint="/purchase_orders/{id}"
        editEndpoint="/purchase_orders/{id}"
        deleteEndpoint="/purchase_orders/{id}"
        deleteCallback={refreshPurchaseOrders}
        editCallback={refreshPurchaseOrders}
        setEditFormFields={updateEditFormFields}
        document="PurchaseOrder"
        title="PurchaseOrder"
        hiddenActions={["edit", "delete"]}
      />
    </Container>
  );
}
