import { useEffect, useState } from "react";
import { Api } from "../helper/Api";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import SplashScreen from "./SplashScreen";
import { useDispatch } from "react-redux";
import {
  setAppFeatures,
  setLoggedInUser,
  storeCustomObjects,
  storeDocumentIdSettings,
  storeGeneralSettings,
  storeMetadata,
} from "../redux/store";
import ClippedHeader from "./ClippedHeader";
import useAppMessage from "../helper/MobileApp";

export default function Layout(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:600px)");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const [featuresLoaded, setFeaturesLoaded] = useState(false);
  const appMessenger = useAppMessage();

  useEffect(() => {
    setGeneralSettings();
    loadFeatures();
    checkUserLoggedIn();
    setDocumentIdSettings();
    setMetadata();
    setCustomObjects();
    appMessenger({ messageType: "appLoaded" });
  }, []);

  const checkUserLoggedIn = () => {
    Api.get(
      "/sessions/logged_in_user",
      {},
      (responseData) => {
        setIsLoggedIn(true);
        dispatch(setLoggedInUser(responseData.user));
        appMessenger({ messageType: "successLoggedIn" });
        setTimeout(checkUserLoggedIn, 60000);
      },
      () => {
        navigate("/login", { replace: true });
      },
    );
  };

  const setDocumentIdSettings = () => {
    Api.get("/settings/document_id_settings", {}, (data) => {
      dispatch(storeDocumentIdSettings(data));
    });
  };

  const setMetadata = () => {
    Api.get("/settings/metadata", {}, (data) => {
      dispatch(storeMetadata(data));
      setMetadataLoaded(true);
    });
  };

  const setGeneralSettings = () => {
    Api.get("/settings/general_settings", {}, (data) => {
      dispatch(storeGeneralSettings(data));
    });
  };

  const loadFeatures = () => {
    Api.get("/features", {}, (data) => {
      dispatch(setAppFeatures(data));
      setFeaturesLoaded(true);
    });
  };

  const setCustomObjects = () => {
    Api.get("/settings/custom_objects", {}, (data) => {
      dispatch(storeCustomObjects(data));
    });
  };

  if (!isLoggedIn || !metadataLoaded || !featuresLoaded) {
    return <SplashScreen show={true} />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <ClippedHeader></ClippedHeader>
      <Box
        component="main"
        sx={{
          marginTop: matches ? "70px" : "60px",
          padding: 0,
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Outlet />
        {props.children}
      </Box>
    </Box>
  );
}
