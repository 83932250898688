import { Box, Fade, Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function SplashScreen(props) {
  return (
    <Fade appear={true} in={props.show} timeout={100}>
      <Box
        alignItems="center"
        sx={{
          height: "95vh",
          display: "flex",
          flexGrow: 1,
        }}
      >
        <div style={{ margin: "0 auto", color: "#009688" }}>
          <Typography variant="h1">Raseeth</Typography>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            Loading...
          </Typography>
        </div>
      </Box>
    </Fade>
  );
}

SplashScreen.propTypes = {
  show: PropTypes.bool,
};
