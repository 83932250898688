import React from "react";
import { Container, createTheme, ThemeProvider } from "@mui/material";
import { themeConfig } from "./helper/theme";
import { SnackbarProvider } from "notistack";
import { Router } from "./Router";
import { Provider as StoreProvider } from "react-redux";
import { RSSlice } from "./redux/store";
import { configureStore } from "@reduxjs/toolkit";
import SecondaryFormDialogue from "./components/common/SecondaryFormDialogue";

const theme = createTheme(themeConfig);

export function App() {
  const store = configureStore({ reducer: RSSlice.reducer });

  return (
    <React.StrictMode>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            dense={true}
            maxSnack={3}
            autoHideDuration={4000}
            variant="info"
            disableWindowBlurListener={true}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                paddingX: { mobile: 0, tablet: 1 },
                paddingBottom: { mobile: 2, tablet: 2 },
              }}
            >
              <Router></Router>
            </Container>
            <SecondaryFormDialogue />
          </SnackbarProvider>
        </ThemeProvider>
      </StoreProvider>
    </React.StrictMode>
  );
}
