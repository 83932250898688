import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import ShowPageDetails from "../../components/common/ShowPageDetails";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Api } from "../../helper/Api";
import useShowField from "../../helper/fields/showMetadata";

export default function ContactShow() {
  const [contact, setContact] = useState({});
  const contactFields = useShowField("Contact");
  const [contactDetails, setContactDetails] = useState([]);

  const { customerId, id } = useParams();

  useEffect(() => {
    setContactDetails(
      contactFields.map((detailField) => {
        return {
          title: detailField.label,
          value: contact[detailField.fieldName],
        };
      })
    );
  }, [contact]);

  useEffect(() => {
    Api.get(`/customers/${customerId}/contacts/${id}`, {}, setContact);
  }, []);

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Customers", link: "/customers" },
          { label: contact.first_name, link: `/customers/${customerId}` },
          { label: "Contacts", link: "" },
        ]}
      />
      <ShowPageTitle title={contact.first_name} />
      <ShowPageDetails values={contactDetails} />
    </Container>
  );
}
