import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListHeader from "../../../components/common/ListHeader";
import ListTable from "../../../components/common/ListTable";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Api } from "../../../helper/Api";
import { useSelector } from "react-redux";

export default function WebhookList() {
  const [webhooks, setWebhooks] = useState([]);
  const formFields = useSelector((state) => state.metadata["Webhook"]);
  const refreshWebhookList = () => {
    Api.get("/settings/webhooks", {}, setWebhooks);
  };

  useEffect(() => {
    refreshWebhookList();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Webhooks", link: "#" },
        ]}
      />
      <ListHeader
        title="Webhook"
        addCallback={refreshWebhookList}
        formFields={formFields}
        endpoint="/settings/webhooks"
        variant="h1"
        document="Webhook"
      />
      <ListTable
        fields={formFields}
        rows={webhooks}
        editEndpoint="/settings/webhooks/{id}"
        hiddenActions={["view"]}
        title="Webhook"
        document="Webhook"
        editCallback={refreshWebhookList}
        deleteEndpoint="/settings/webhooks/{id}"
        deleteCallback={refreshWebhookList}
      />
    </Container>
  );
}
