import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import { Container } from "@mui/material";
import ListTable from "../../../components/common/ListTable";
import ListHeaderReadOnly from "../../../components/common/ListHeaderReadOnly";
import { useEffect, useState } from "react";
import { Api } from "../../../helper/Api";

const formFields = [
  {
    label: "Document",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "document",
  },
  {
    label: "Is Manual",
    defaultValue: false,
    required: true,
    fieldType: "toggle",
    fieldName: "is_manual",
  },
  {
    label: "Prefix",
    defaultValue: "",
    required: false,
    fieldType: "text",
    fieldName: "prefix",
  },
  {
    label: "Start Index",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "start_index",
  },
];

export default function DocumentNumberingList() {
  const [documentIdSettings, setDocumentIdSettings] = useState([]);

  const refreshDocumentIdSettings = () => {
    Api.get("/settings/document_id_settings", {}, setDocumentIdSettings);
  };

  useEffect(() => {
    refreshDocumentIdSettings();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Document Numbering", link: "#" },
        ]}
      />
      <ListHeaderReadOnly title="Document Numbering" variant="h1" />
      <ListTable
        fields={formFields}
        title={""}
        rows={documentIdSettings}
        editEndpoint="/settings/document_id_settings/{id}"
        showEndpoint="/settings/document_id_settings/{id}"
        hiddenActions={["view", "delete"]}
        editCallback={refreshDocumentIdSettings}
      />
    </Container>
  );
}
