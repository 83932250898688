import TabContext from "@mui/lab/TabContext";
import { Box } from "@mui/material";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";
import ListTable from "../../components/common/ListTable";
import ListHeader from "../../components/common/ListHeader";
import { useSelector } from "react-redux";
import useFeatureEnabled from "../../helper/Feature";

export default function CustomerAssociations(props) {
  const contactFields = useSelector((state) => state.metadata["Contact"]);
  const [activeTab, setActiveTab] = useState("contacts");
  const [contacts, setContacts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [statements, setStatements] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const featureEnabled = useFeatureEnabled();

  const paymentFields = useSelector((state) => state.metadata["Payment"]);
  const statementFields = useSelector((state) => state.metadata["Statement"]);
  const invoiceFields = useSelector((state) => state.metadata["Invoice"]);
  const purchaseOrderFields = useSelector(
    (state) => state.metadata["PurchaseOrder"]
  );

  const refreshContacts = (params = {}) => {
    Api.get(`/customers/${props.customerId}/contacts`, params, (data) => {
      setContacts(data);
    });
  };

  const refreshPayments = (params = {}) => {
    const args = params;
    args["customer_id"] = props.customerId;

    Api.get("/payments", args, setPayments);
  };

  const addPaymentCallback = () => {
    refreshPayments();
    props.refreshCustomer();
  };

  const refreshStatements = (params = {}) => {
    const args = params;
    args["customer_id"] = props.customerId;

    Api.get("/statements", args, setStatements);
  };

  const refreshInvoices = (params = {}) => {
    const args = params;
    args["customer_id"] = props.customerId;

    Api.get("/invoices", args, setInvoices);
  };

  const addInvoiceCallback = () => {
    refreshInvoices();
    props.refreshCustomer();
  };

  const refreshPurchaseOrders = (params = {}) => {
    const args = params;
    args["customer_id"] = props.customerId;

    Api.get("/purchase_orders", args, setPurchaseOrders);
  };

  const addPurchaseOrderCallback = () => {
    refreshPurchaseOrders();
    props.refreshCustomer();
  };

  const setDefaultCustomerId = (formFields) => {
    return formFields.map((formField) => {
      const newFormField = Object.assign({}, formField);
      if (formField.fieldName === "customer_id") {
        newFormField.defaultValue = props.customerId;
        newFormField.disabled = true;
      }
      return newFormField;
    });
  };

  const paymentFieldsWithCustomerDefaulted =
    setDefaultCustomerId(paymentFields);
  const statementFieldsWithCustomerDefaulted =
    setDefaultCustomerId(statementFields);
  const invoiceFieldsWithCustomerDefaulted =
    setDefaultCustomerId(invoiceFields);
  const purchaseOrderFieldsWithCustomerDefaulted =
    setDefaultCustomerId(purchaseOrderFields);

  return (
    <Box sx={{ mt: 3, width: "100%", typography: "body1" }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(event, newValue) => {
              setActiveTab(newValue);
            }}
            aria-label="Associations"
          >
            <Tab label="Contacts" value="contacts" />
            <Tab label="Payments" value="payments" />
            <Tab label="Invoices" value="invoices" />
            {featureEnabled("products.purchase_order") ? (
              <Tab label="Purchase Orders" value="purchaseOrders" />
            ) : (
              ""
            )}
            <Tab label="Statements" value="statements" />
          </TabList>
        </Box>
        <TabPanel value="contacts">
          <ListHeader
            title="Contacts"
            addCallback={refreshContacts}
            formFields={contactFields}
            endpoint={`/customers/${props.customerId}/contacts`}
            variant="h6"
            document="Contact"
            filterCallback={refreshContacts}
          />
          <ListTable
            fields={contactFields}
            rows={contacts}
            editEndpoint={`/customers/${props.customerId}/contacts/{id}`}
            editCallback={refreshContacts}
            deleteEndpoint={`/customers/${props.customerId}/contacts/{id}`}
            deleteCallback={refreshContacts}
            title="Contacts"
            showEndpoint={`/customers/${props.customerId}/contacts/{id}`}
            document="Contact"
          />
        </TabPanel>
        <TabPanel value="payments">
          <ListHeader
            title="Payments"
            addCallback={addPaymentCallback}
            formFields={paymentFieldsWithCustomerDefaulted}
            endpoint={`/payments`}
            variant="h6"
            document="Payment"
            filterCallback={refreshPayments}
          />
          <ListTable
            fields={paymentFields}
            rows={payments}
            editEndpoint={`/payments/{id}`}
            editCallback={addPaymentCallback}
            deleteEndpoint={`/payments/{id}`}
            deleteCallback={addPaymentCallback}
            title="Payments"
            showEndpoint={`/payments/{id}`}
            document="Payment"
          />
        </TabPanel>
        <TabPanel value="invoices">
          <ListHeader
            title="Invoices"
            addCallback={addInvoiceCallback}
            formFields={invoiceFieldsWithCustomerDefaulted}
            endpoint={`/invoices`}
            variant="h6"
            document="Invoice"
            filterCallback={refreshInvoices}
          />
          <ListTable
            fields={invoiceFields}
            rows={invoices}
            editCallback={addInvoiceCallback}
            deleteEndpoint={`/invoices/{id}`}
            deleteCallback={addInvoiceCallback}
            hiddenActions={["edit"]}
            title="Invoices"
            showEndpoint={`/invoices/{id}`}
            document="Statement"
          />
        </TabPanel>
        <TabPanel value="purchaseOrders">
          <ListHeader
            title="Purchase Orders"
            addCallback={addPurchaseOrderCallback}
            formFields={purchaseOrderFieldsWithCustomerDefaulted}
            endpoint={`/purchase_orders`}
            variant="h6"
            document="PurchaseOrder"
            filterCallback={refreshPurchaseOrders}
          />
          <ListTable
            fields={purchaseOrderFields}
            rows={purchaseOrders}
            editCallback={addPurchaseOrderCallback}
            deleteEndpoint={`/purchase_orders/{id}`}
            deleteCallback={addPurchaseOrderCallback}
            hiddenActions={["edit"]}
            title="Purchase Orders"
            showEndpoint={`/purchase_orders/{id}`}
            document="PurchaseOrder"
          />
        </TabPanel>
        <TabPanel value="statements">
          <ListHeader
            title="Statements"
            addCallback={refreshStatements}
            formFields={statementFieldsWithCustomerDefaulted}
            endpoint={`/statements`}
            variant="h6"
            document="Statement"
            filterCallback={refreshStatements}
          />
          <ListTable
            fields={statementFields}
            rows={statements}
            editCallback={refreshStatements}
            deleteEndpoint={`/statements/{id}`}
            deleteCallback={refreshStatements}
            hiddenActions={["edit"]}
            title="Statements"
            showEndpoint={`/statements/{id}`}
            document="Statement"
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

CustomerAssociations.propTypes = {
  customerId: PropTypes.string,
  refreshCustomer: PropTypes.func,
};
