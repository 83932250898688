import { useEffect, useState } from "react";
import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListTable from "../../../components/common/ListTable";
import { Container } from "@mui/material";
import { Api } from "../../../helper/Api";
import { useSelector } from "react-redux";
import ListHeader from "../../../components/common/ListHeader";

export default function CustomObjectsList() {
  const [customObjectsList, setCustomObjectsList] = useState([]);
  const formFields = useSelector((state) => state.metadata["CustomObject"]);
  const refreshCustomObjectsList = () => {
    Api.get("/settings/custom_objects", {}, setCustomObjectsList);
  };

  const setEditFormFields = (
    customObject,
    setEditFormFieldsCallback,
    setEditFormOpened,
  ) => {
    const newCustomObjectFields = formFields.map((customObjectField) => {
      const newCustomObjectField = Object.assign({}, customObjectField);

      newCustomObjectField.defaultValue =
        customObject[customObjectField.fieldName];
      return newCustomObjectField;
    });

    const customObjectFFields = newCustomObjectFields.find(
      (field) => field.fieldName === "custom_object_fields",
    );

    customObjectFFields.formFields = customObject.custom_object_fields.map(
      (customObjectField) => {
        return customObjectFFields.formFields.map((formField) => {
          const newFormField = Object.assign({}, formField);
          const fieldName = newFormField.fieldName.match(
            /custom_object_fields_attributes\[\]\[(.*)\]/,
          )[1];
          newFormField.defaultValue = customObjectField[fieldName];
          return newFormField;
        });
      },
    );
    setEditFormFieldsCallback(newCustomObjectFields);
    setEditFormOpened(true);
  };

  useEffect(() => {
    refreshCustomObjectsList();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Custom Objects", link: "#" },
        ]}
      />
      <ListHeader
        title="Custom Objects"
        addCallback={refreshCustomObjectsList}
        formFields={formFields}
        endpoint="/settings/custom_objects"
        variant="h1"
        document="CustomObject"
      />
      <ListTable
        fields={formFields}
        rows={customObjectsList}
        editEndpoint="/settings/custom_objects/{id}"
        hiddenActions={["view"]}
        title="Custom Object"
        document="CustomObject"
        editCallback={refreshCustomObjectsList}
        setEditFormFields={setEditFormFields}
        deleteEndpoint="/settings/custom_objects/{id}"
        deleteCallback={refreshCustomObjectsList}
      />
    </Container>
  );
}
