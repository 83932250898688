import { TextField } from "@mui/material";
import PropTypes from "prop-types";

export default function RSDocumentID(props) {
  const defaultValue = props.defaultValue
    ? props.defaultValue
    : "Auto Generated";
  return (
    <>
      <TextField disabled label="ID" defaultValue={defaultValue} />
      <TextField
        sx={{ display: "none" }}
        defaultValue={defaultValue}
        name={props.fieldName}
      />
    </>
  );
}

RSDocumentID.propTypes = {
  defaultValue: PropTypes.string,
  fieldName: PropTypes.string,
};
