import axios from "axios";
import qs from "qs";

export class Api {
  static apiClient() {
    return axios.create({
      baseURL: process.env.API_BASE_URL,
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    });
  }
  static post(url, data, successCallback, failureCallback) {
    this.apiClient()
      .post(url, data)
      .then((response) => {
        this.handleSuccess(response, successCallback);
      })
      .catch((error) => {
        this.handleFailure(error, failureCallback);
      });
  }

  static put(url, data, successCallback, failureCallback) {
    this.apiClient()
      .put(url, data)
      .then((response) => {
        this.handleSuccess(response, successCallback);
      })
      .catch((error) => {
        this.handleFailure(error, failureCallback);
      });
  }

  static get(url, data, successCallback, failureCallback) {
    this.apiClient()
      .get(url, {
        params: data,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "brackets" });
        },
      })
      .then((response) => {
        this.handleSuccess(response, successCallback);
      })
      .catch((error) => {
        this.handleFailure(error, failureCallback);
      });
  }

  static delete(url, successCallback, failureCallback) {
    this.apiClient()
      .delete(url)
      .then((response) => {
        this.handleSuccess(response, successCallback);
      })
      .catch((error) => {
        this.handleFailure(error, failureCallback);
      });
  }

  static handleSuccess(response, successCallback) {
    if (response.data) {
      successCallback(response.data);
    }
  }

  static handleFailure(error, failureCallback) {
    console.log("Error happened on API", error);

    if (error.response && error.response.data && failureCallback) {
      failureCallback(error.response.data);
    } else if (failureCallback) {
      failureCallback({ message: "Unhandled server error happened" });
    } else if (error.response.status === 401) {
      this.redirectToLogin();
      return;
    }
  }

  static redirectToLogin() {
    window.location.href = "/login";
  }
}
