import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import Form from "./Form";
import { Api } from "../../helper/Api";
import { useSnackbar } from "notistack";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} timeout={500} {...props} />;
});

export default function FormDialogue(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    // Calling the API action dynamically
    Api[props.apiAction](
      props.endpoint,
      formData,
      (data) => {
        enqueueSnackbar("Your last action was successful", {
          variant: "success",
        });
        props.handleClose();
      },
      (errorData) => {
        enqueueSnackbar(errorData.message, { variant: "error" });
      },
    );
  };
  const formId = `form-id-${props.title.replace(/\s/g, "")}`;

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.title}
          </Typography>
          <Button color="inherit" autoFocus form={formId} type="submit">
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Form
        handleSubmit={handleSubmit}
        key={`form-${props.title}`}
        formFields={props.formFields}
        boxKey={`form-box-${props.title}`}
        formId={formId}
        document={props.document}
      />
    </Dialog>
  );
}

FormDialogue.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  formFields: PropTypes.array,
  endpoint: PropTypes.string,
  apiAction: PropTypes.string,
  document: PropTypes.string,
};
