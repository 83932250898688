import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListHeader from "../../../components/common/ListHeader";
import ListTable from "../../../components/common/ListTable";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Api } from "../../../helper/Api";
import { useSelector } from "react-redux";

const formFields = [
  {
    label: "Currency",
    fieldType: "select",
    fieldName: "currency_id",
    defaultValue: "",
    availableValues: [],
    labelField: "name",
  },
  {
    label: "Enabled",
    fieldType: "toggle",
    fieldName: "enabled",
    defaultValue: true,
  },
  {
    label: "Rate",
    defaultValue: "1",
    fieldType: "text",
    fieldName: "rate",
  },
];

export default function EntityCurrency() {
  const [currencies, setCurrencies] = useState([]);
  const [currencyFormFields, setCurrencyFormFields] = useState([]);
  const currentCurrency = useSelector(
    (state) => state.generalSettings.currency
  );

  const setCurrencyField = (currencies) => {
    setCurrencyFormFields(
      formFields.map((field) => {
        if (field.fieldName == "currency_id") {
          field.availableValues = currencies.filter((currency) => {
            return currency.label !== currentCurrency.name;
          });
        }
        return field;
      })
    );
  };

  useEffect(() => {
    getCurrencies();
    refreshCurrencies();
  }, []);

  const getCurrencies = () => {
    Api.get("/autocomplete?object=Currency", {}, setCurrencyField);
  };

  const refreshCurrencies = () => {
    Api.get("/settings/currencies", {}, setCurrencies);
  };

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "" },
          { label: "Currencies", link: "" },
        ]}
      />
      <ListHeader
        title="Currency"
        addCallback={refreshCurrencies}
        formFields={currencyFormFields}
        endpoint="/settings/currencies"
        variant="h1"
        document="Invoice"
      />
      <p>Displays currencies enabled other than base currency.</p>
      <ListTable
        fields={currencyFormFields}
        rows={currencies}
        editEndpoint="/settings/currencies/{id}"
        editCallback={refreshCurrencies}
        deleteEndpoint="/settings/currencies/{id}"
        deleteCallback={refreshCurrencies}
        showEndpoint="/settings/currencies/{id}"
        document="Currency"
        title="Currency Setup"
      />
    </Container>
  );
}
