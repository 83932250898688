import { useParams } from "react-router-dom";
import { Api } from "../../helper/Api";
import { useEffect, useState } from "react";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import { Container } from "@mui/material";
import ShowPageDetails from "../../components/common/ShowPageDetails";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import useShowField from "../../helper/fields/showMetadata";
import { useCurrencySymbol } from "../../helper/currencies";
import { rowLabel } from "../../helper/TransformHelper";
import useFeatureEnabled from "../../helper/Feature";
import ProductAssociation from "./ProductAssociation";

export default function ProductShow() {
  const currencySymbol = useCurrencySymbol();
  const [product, setProduct] = useState({});
  const [productDetails, setproductDetails] = useState([]);
  const productFields = useShowField("Product");
  const { id } = useParams();
  const featureEnabled = useFeatureEnabled();

  useEffect(() => {
    Api.get(`/products/${id}`, {}, (data) => {
      setProduct(data);
    });
  }, []);

  useEffect(() => {
    setproductDetails(
      productFields.map((detailField) => {
        return {
          title: detailField.label,
          value: rowLabel(product, detailField, currencySymbol),
        };
      })
    );
  }, [product]);

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Products", link: "/products" },
          { label: product.document_identifier, link: "/products" },
        ]}
      />
      <ShowPageTitle title={product.name} />
      <ShowPageDetails values={productDetails} />
      {featureEnabled("products.inventory") && product.inventory_enabled ? (
        <ProductAssociation productId={id} />
      ) : (
        ""
      )}
    </Container>
  );
}
