import PropTypes from "prop-types";
import { Button, FormControl, FormLabel, Grid } from "@mui/material";

export default function RSFileUpload(props) {
  return (
    <FormControl fullWidth>
      <Grid container>
        <Grid mobile={6} tablet={6}>
          <FormLabel id="radio-buttons-group-label">
            {props.label}: &nbsp;&nbsp;
            <Button variant="contained" component="label" fullWidth={false}>
              Upload
              <input
                hidden
                accept="*"
                multiple
                type="file"
                name={props.fieldName}
              />
            </Button>
          </FormLabel>
        </Grid>
        <Grid mobile={6} tablet={6} style={{ overflow: "hidden", height: 80 }}>
          {props.defaultValue ? (
            <img
              height={70}
              src={props.defaultValue}
              style={{ marginLeft: 5, float: "left" }}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </FormControl>
  );
}

RSFileUpload.propTypes = {
  label: PropTypes.string,
  fieldName: PropTypes.string,
  defaultValue: PropTypes.string,
};
