import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ListHeader from "../../components/common/ListHeader";
import ListTable from "../../components/common/ListTable";
import { Container, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Api } from "../../helper/Api";
import SelectTemplateDialogue from "./SelectTemplateDialogue";
import { useNavigate } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { setEditFormFields } from "../../helper/fields/invoiceForm";

export default function InvoiceList() {
  const [invoices, setInvoices] = useState([]);
  const invoiceFields = useSelector((state) => state.metadata["Invoice"]);
  const [openTemplateDialogue, setOpenTemplateDialogue] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [printMode, setPrintMode] = useState(false);
  const navigate = useNavigate();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");

  const refreshInvoiceList = (params = {}) => {
    Api.get("/invoices", params, setInvoices);
  };

  const getInvoiceId = (menuClickEvent) => {
    const invoiceId =
      menuClickEvent.target.parentNode.parentNode.parentNode.getAttribute(
        "actionable-object-id"
      );
    return invoiceId;
  };

  const previewInvoice = (event) => {
    setSelectedInvoiceId(getInvoiceId(event));
    setPreviewMode(true);
    setOpenTemplateDialogue(true);
  };

  const downloadPdf = (event) => {
    setSelectedInvoiceId(getInvoiceId(event));
    setPreviewMode(false);
    setPrintMode(false);
    setOpenTemplateDialogue(true);
  };

  const printPdf = (event) => {
    setSelectedInvoiceId(getInvoiceId(event));
    setPreviewMode(false);
    setPrintMode(true);
    setOpenTemplateDialogue(true);
  };

  const updateEditFormFields = (
    invoice,
    setEditFormFieldsCallback,
    setEditFormOpened
  ) => {
    setEditFormFields(
      invoiceFields,
      invoice,
      setEditFormFieldsCallback,
      setEditFormOpened
    );
  };

  const onSelectedTemplate = (templateId) => {
    if (templateId && previewMode) {
      const previewUrl = `/invoices/${selectedInvoiceId}/preview/${templateId}`;
      navigate(previewUrl, { replace: false });
    } else if (templateId && printMode) {
      const printUrl = `/invoices/${selectedInvoiceId}/print/${templateId}`;
      navigate(printUrl, { replace: false });
    } else if (templateId) {
      const downloadUrl = `${
        process.env.API_BASE_URL
      }/invoices/${selectedInvoiceId}/pdf?template_id=${templateId}&jwt_token=${localStorage.getItem(
        "access_token"
      )}`;
      window.open(downloadUrl, "_blank").focus();
      setOpenTemplateDialogue(false);
    }
  };

  const additionalMenuItems = [
    <MenuItem onClick={previewInvoice} key="Preview-Menu">
      <ReceiptIcon />
      &nbsp;Preview
    </MenuItem>,
    <MenuItem onClick={downloadPdf} key="Download-Menu">
      <DownloadIcon />
      &nbsp;Download PDF
    </MenuItem>,
    <MenuItem onClick={printPdf} key="Print-Menu">
      <PrintIcon />
      &nbsp;Print
    </MenuItem>,
  ];

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb menuStack={[{ label: "Invoices", link: "/invoices" }]} />
      <ListHeader
        title="Invoices"
        addCallback={refreshInvoiceList}
        formFields={invoiceFields}
        endpoint="/invoices"
        variant="h1"
        filterCallback={refreshInvoiceList}
        document="Invoice"
      />
      <ListTable
        fields={invoiceFields}
        rows={invoices}
        editEndpoint="/invoices/{id}"
        editCallback={refreshInvoiceList}
        deleteEndpoint="/invoices/{id}"
        deleteCallback={refreshInvoiceList}
        showEndpoint="/invoices/{id}"
        document="Invoice"
        setEditFormFields={updateEditFormFields}
        title="Invoice"
        additionalActionMenu={additionalMenuItems}
      />
      <SelectTemplateDialogue
        open={openTemplateDialogue}
        setOpenTemplateDialogue={setOpenTemplateDialogue}
        onSelectedTemplate={onSelectedTemplate}
      />
    </Container>
  );
}
