import { useParams } from "react-router-dom";
import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import { Container } from "@mui/material";
import ListHeader from "../../../components/common/ListHeader";
import ListTable from "../../../components/common/ListTable";
import { useState } from "react";
import { Api } from "../../../helper/Api";

const extendObjectFields = [
  {
    label: "Name",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "name",
  },
  {
    label: "Label",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "label",
  },
  {
    label: "Input Type",
    defaultValue: "",
    required: true,
    fieldType: "select",
    fieldName: "input_type",
    availableValues: [
      { label: "Text", id: "text" },
      { label: "Date", id: "date" },
      { label: "Number", id: "number" },
      { label: "Select", id: "select" },
      { label: "Boolean", id: "boolean" },
      { label: "Radio", id: "radio" },
    ],
  },
  {
    label: "Required",
    defaultValue: false,
    required: true,
    fieldType: "toggle",
    fieldName: "is_required",
  },
  {
    label: "Available Values",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "available_values",
    showOnly: [{ fieldName: "input_type", values: ["select"] }],
  },
  {
    label: "Default Value",
    defaultValue: "",
    required: false,
    fieldType: "text",
    fieldName: "default_value",
  },
  {
    label: "Show In List Page",
    defaultValue: false,
    required: false,
    fieldType: "toggle",
    fieldName: "display_in_list",
  },
];

export default function ExtendObjectShow() {
  const { id } = useParams();
  const [definedFields, setDefinedFields] = useState([]);

  const refreshObjectFields = () => {
    Api.get(`/settings/extend_objects/${id}`, {}, setDefinedFields);
  };

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "#" },
          { label: "Extend Objects", link: "/settings/extend_objects" },
          { label: id, link: "#" },
        ]}
      />
      <ListHeader
        title={`${id} Fields`}
        addCallback={refreshObjectFields}
        formFields={extendObjectFields}
        endpoint={`/settings/extend_objects?extended_object=${id}`}
        variant="h1"
        filterCallback={refreshObjectFields}
      />
      <ListTable
        fields={extendObjectFields}
        rows={definedFields}
        editEndpoint={`/settings/extend_objects/{id}?extended_object=${id}`}
        editCallback={refreshObjectFields}
        deleteEndpoint={`/settings/extend_objects/{id}?extended_object=${id}`}
        deleteCallback={refreshObjectFields}
        title={`${id} Fields`}
        hiddenActions={["view"]}
      />
    </Container>
  );
}
