import { Container } from "@mui/material";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import ShowPageDetails from "../../components/common/ShowPageDetails";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../helper/Api";
import { rowLabel } from "../../helper/TransformHelper";
import useShowField from "../../helper/fields/showMetadata";
import { useCurrencySymbol } from "../../helper/currencies";
import PaymentAssociation from "./PaymentAssociation";

export default function PaymentShow(props) {
  const [payment, setPayment] = useState({});
  const [paymentDetails, setPaymentDetails] = useState([]);
  const { id } = useParams();
  const paymentFields = useShowField("Payment");
  const currencySymbol = useCurrencySymbol();

  useEffect(() => {
    Api.get(`/payments/${id}`, {}, (data) => {
      setPayment(data);
    });
  }, []);

  useEffect(() => {
    setPaymentDetails(
      paymentFields.map((detailField) => {
        return {
          title: detailField.label,
          value: rowLabel(payment, detailField, currencySymbol),
        };
      })
    );
  }, [payment]);
  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Payments", link: "/payments" },
          { label: payment.document_identifier, link: "/payments" },
        ]}
      />
      <ShowPageTitle title={`Payment: ${payment.document_identifier}`} />
      <ShowPageDetails values={paymentDetails} />
      <PaymentAssociation paymentId={id} />
    </Container>
  );
}
