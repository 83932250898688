import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import ShowPageDetails from "../../components/common/ShowPageDetails";
import { Container, MenuItem } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Api } from "../../helper/Api";
import { rowLabel } from "../../helper/TransformHelper";
import useShowField from "../../helper/fields/showMetadata";
import InvoiceAssociation from "./InvoiceAssociation";
import { useCurrencySymbol } from "../../helper/currencies";
import MoreActionButton from "../../components/common/MoreActionButton";
import SelectTemplateDialogue from "./SelectTemplateDialogue";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import FormDialogue from "../../components/common/FormDialogue";
import ConfirmationDialogue from "../../components/common/ConfirmationDialogue";
import { useSnackbar } from "notistack";
import { setEditFormFields } from "../../helper/fields/invoiceForm";
import { useSelector } from "react-redux";

export default function InvoiceShow() {
  const [editFormOpened, setEditFormOpened] = useState(false);
  const [deleteConfirmOpened, setDeleteConfirmOpened] = useState(false);
  const currencySymbol = useCurrencySymbol();
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [openTemplateDialogue, setOpenTemplateDialogue] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [printMode, setPrintMode] = useState(false);
  const invoiceFields = useShowField("Invoice");
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [formFields, setFormFields] = useState([]);
  const invoiceEditFields = useSelector((state) => state.metadata["Invoice"]);

  useEffect(() => {
    refreshInvoice();
  }, []);

  const refreshInvoice = () => {
    Api.get(`/invoices/${id}`, {}, setInvoice);
  };

  useEffect(() => {
    setInvoiceDetails(
      invoiceFields.map((detailField) => {
        return {
          title: detailField.label,
          value: rowLabel(invoice, detailField, currencySymbol),
        };
      })
    );
  }, [invoice]);

  const previewInvoice = () => {
    setPreviewMode(true);
    setOpenTemplateDialogue(true);
  };

  const downloadPdf = () => {
    setPreviewMode(false);
    setPrintMode(false);
    setOpenTemplateDialogue(true);
  };

  const printPdf = (event) => {
    setPreviewMode(false);
    setPrintMode(true);
    setOpenTemplateDialogue(true);
  };

  const onSelectedTemplate = (templateId) => {
    if (templateId && previewMode) {
      const previewUrl = `/invoices/${id}/preview/${templateId}`;
      navigate(previewUrl, { replace: false });
    } else if (templateId && printMode) {
      const printUrl = `/invoices/${id}/print/${templateId}`;
      navigate(printUrl, { replace: false });
    } else if (templateId) {
      const downloadUrl = `${
        process.env.API_BASE_URL
      }/invoices/${id}/pdf?template_id=${templateId}&jwt_token=${localStorage.getItem(
        "access_token"
      )}`;
      window.open(downloadUrl, "_blank").focus();
      setOpenTemplateDialogue(false);
    }
  };

  const handleEdit = () => {
    setEditFormFields(
      invoiceEditFields,
      invoice,
      setFormFields,
      setEditFormOpened
    );
  };

  const handleDelete = () => {
    setDeleteConfirmOpened(true);
  };

  const deleteConfirmationCallback = () => {
    setDeleteConfirmOpened(false);
    Api.delete(
      `/invoices/${invoice.document_identifier}`,
      () => {
        enqueueSnackbar("Successfully deleted", {
          variant: "success",
        });
        navigate("/invoices", { replace: true });
      },
      (errorData) => {
        enqueueSnackbar(errorData.message, {
          variant: "error",
        });
      }
    );
  };

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Invoices", link: "/invoices" },
          { label: invoice.document_identifier, link: "/invoices" },
        ]}
      />
      <ShowPageTitle title={invoice.document_identifier}>
        <MoreActionButton>
          <MenuItem key={`edit-menu-${invoice.id}`} onClick={handleEdit}>
            <EditOutlinedIcon />
            &nbsp;Edit
          </MenuItem>
          <MenuItem key={`delete-menu-${invoice.id}`} onClick={handleDelete}>
            <DeleteSweepOutlinedIcon />
            &nbsp;Delete
          </MenuItem>
          <MenuItem onClick={previewInvoice} key="Preview-Menu">
            <ReceiptIcon />
            &nbsp;Preview
          </MenuItem>
          <MenuItem onClick={downloadPdf} key="Download-Menu">
            <DownloadIcon />
            &nbsp;Download PDF
          </MenuItem>
          <MenuItem onClick={printPdf} key="Print-Menu">
            <PrintIcon />
            &nbsp;Print
          </MenuItem>
        </MoreActionButton>
      </ShowPageTitle>
      <ShowPageDetails values={invoiceDetails} />
      <InvoiceAssociation
        invoiceId={id}
        customerId={invoice.customer_id}
        invoiceBalance={invoice.balance}
        refreshInvoice={refreshInvoice}
      />
      <SelectTemplateDialogue
        open={openTemplateDialogue}
        setOpenTemplateDialogue={setOpenTemplateDialogue}
        onSelectedTemplate={onSelectedTemplate}
      />
      <FormDialogue
        open={editFormOpened}
        handleClose={() => {
          setEditFormOpened(false);
          refreshInvoice();
        }}
        title={`Edit Invoice ${invoice.document_identifier}`}
        formFields={formFields}
        endpoint={`/invoices/${invoice.document_identifier}`}
        apiAction="put"
        document="Invoice"
      />
      <ConfirmationDialogue
        confirmationCallback={deleteConfirmationCallback}
        description="Are you sure to delete ?"
        open={deleteConfirmOpened}
        cancelCallback={() => {
          setDeleteConfirmOpened(false);
        }}
      />
    </Container>
  );
}
