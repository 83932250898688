import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Api } from "../../helper/Api";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { callRSAutocompleteRefresh, closeForm } from "../../redux/store";
import Form from "./Form";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} timeout={500} {...props} />;
});

export default function SecondaryFormDialogue(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [formFields, setFormFields] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [apiAction, setApiAction] = useState("");
  const [endpoint, setEndpoint] = useState("");
  const [document, setDocument] = useState();
  const [closeDispatchEvent, setCloseDispatchEvent] = useState(() => {});
  const dispatch = useDispatch();

  const formData = useSelector((state) => {
    if (state.openedForms.length > 0) {
      return state.openedForms.at(-1);
    }
  });

  useEffect(() => {
    if (formData) {
      setFormFields(formData.formFields);
      setTitle(formData.title);
      setApiAction(formData.apiAction);
      setEndpoint(formData.endpoint);
      setDocument(formData.document);
      setCloseDispatchEvent(formData.dispatchEvent);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [formData]);

  const handleClose = () => {
    dispatch(closeForm);
    if (closeDispatchEvent === "callRSAutocompleteRefresh") {
      dispatch(callRSAutocompleteRefresh());
    }
    setOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    // Calling the API action dynamically
    Api[apiAction](
      endpoint,
      formData,
      (data) => {
        enqueueSnackbar("Your last action was successful", {
          variant: "success",
        });
        handleClose();
      },
      (errorData) => {
        enqueueSnackbar(errorData.message, { variant: "error" });
      }
    );
  };

  const formId = `form-id-${title.replace(/\s/g, "")}`;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.title}
          </Typography>
          <Button color="inherit" autoFocus form={formId} type="submit">
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Form
        handleSubmit={handleSubmit}
        key={`form-${title}`}
        formFields={formFields}
        boxKey={`form-box-${title}`}
        formId={formId}
        document={document}
      />
    </Dialog>
  );
}

SecondaryFormDialogue.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  formFields: PropTypes.array,
  endpoint: PropTypes.string,
};
