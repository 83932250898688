import { Api } from "../../helper/Api";
import { Container } from "@mui/material";
import ListHeader from "../../components/common/ListHeader";
import ListTable from "../../components/common/ListTable";
import { useEffect, useState } from "react";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import { useSelector } from "react-redux";

export default function CustomerList() {
  const [customers, setCustomers] = useState([]);
  const customerFields = useSelector((state) => state.metadata["Customer"]);

  const getCustomers = (params = {}) => {
    Api.get("/customers", params, (data) => {
      setCustomers(data);
    });
  };

  const refreshCustomersList = (params = {}) => {
    getCustomers(params);
  };

  const editFormFields = customerFields.filter(
    (field) => field.fieldName !== "contacts"
  );

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb menuStack={[{ label: "Customers", link: "" }]} />
      <ListHeader
        title="Customers"
        addCallback={refreshCustomersList}
        formFields={customerFields}
        endpoint="/customers"
        variant="h1"
        filterCallback={refreshCustomersList}
        document="Customer"
      />
      <ListTable
        fields={editFormFields}
        rows={customers}
        editEndpoint="/customers/{id}"
        editCallback={refreshCustomersList}
        deleteEndpoint="/customers/{id}"
        deleteCallback={refreshCustomersList}
        title="Customers"
        showEndpoint="/customers/{id}"
        document="Customer"
      />
    </Container>
  );
}
