import { Api } from "../Api";

export const setEditFormFields = (
  purchaseOrderFields,
  purchaseOrder,
  setEditFormFieldsCallback,
  setEditFormOpened
) => {
  Api.get(
    `/purchase_orders/${purchaseOrder.document_identifier}/purchase_order_lines`,
    {},
    (purchaseOrderLines) => {
      const newPurchaseOrderFields = purchaseOrderFields.map(
        (purchaseOrderField) => {
          const newPurchaseOrderField = Object.assign({}, purchaseOrderField);
          if (newPurchaseOrderField.fieldName === "customer_id") {
            newPurchaseOrderField.disabled = true;
          }
          newPurchaseOrderField.defaultValue =
            purchaseOrder[purchaseOrderField.fieldName];
          return newPurchaseOrderField;
        }
      );

      const purchaseOrderLineField = newPurchaseOrderFields.find(
        (field) => field.fieldName === "purchase_order_lines"
      );
      purchaseOrderLineField.formFields = purchaseOrderLines.map(
        (purchaseOrderLine) => {
          return purchaseOrderLineField.formFields.map((lineField) => {
            const newLineField = Object.assign({}, lineField);
            const fieldName = newLineField.fieldName.match(
              /purchase_order_lines_attributes\[\]\[(.*)\]/
            )[1];
            newLineField.defaultValue = purchaseOrderLine[fieldName];
            return newLineField;
          });
        }
      );

      setEditFormFieldsCallback(newPurchaseOrderFields);
      setEditFormOpened(true);
    }
  );
};
