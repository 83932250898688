import useShowField from "../../helper/fields/showMetadata";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import { Container } from "@mui/material";
import ShowPageTitle from "../../components/common/ShowPageTitle";
import ShowPageDetails from "../../components/common/ShowPageDetails";
import { rowLabel } from "../../helper/TransformHelper";
import { useCurrencySymbol } from "../../helper/currencies";
import PurchaseOrderAssociation from "./PurchaseOrderAssociation";

export default function PurchaseOrderShow() {
  const purchaseOrderFields = useShowField("PurchaseOrder");
  const { id } = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState([]);
  const currencySymbol = useCurrencySymbol();

  useEffect(() => {
    refreshPurchaseOrder();
  }, []);

  useEffect(() => {
    setPurchaseOrderDetails(
      purchaseOrderFields.map((detailField) => {
        return {
          title: detailField.label,
          value: rowLabel(purchaseOrder, detailField, currencySymbol),
        };
      })
    );
  }, [purchaseOrder]);

  const refreshPurchaseOrder = () => {
    Api.get(`/purchase_orders/${id}`, {}, setPurchaseOrder);
  };

  return (
    <Container maxWidth="xl" style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Purchase Order", link: "/purchase_orders" },
          {
            label: purchaseOrder.document_identifier,
            link: "/purchase_orders",
          },
        ]}
      />
      <ShowPageTitle title={purchaseOrder.document_identifier}></ShowPageTitle>
      <ShowPageDetails values={purchaseOrderDetails} />
      <PurchaseOrderAssociation purchaseOrderId={id} />
    </Container>
  );
}
