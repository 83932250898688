import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import FieldComponent from "./FieldComponent";
import { handleInvoiceFormChange } from "../../helper/fields/invoiceForm";
import { setStateEndpoint } from "../../helper/country_state";

export default function Form(props) {
  const [fieldValues, setFieldValues] = useState({});
  // Display only fields will not be shown on form
  const [formFields, setFormFields] = useState([]);

  const onFieldValueChange = (fieldName, newValue) => {
    setFieldValues({ ...fieldValues, [fieldName]: newValue });

    if (props.document === "Invoice") {
      handleInvoiceFormChange(fieldName, newValue, formFields, setFormFields);
    }

    if (fieldName === "country") {
      setStateEndpoint(newValue, formFields, setFormFields);
    }
  };

  useEffect(() => {
    setFormFields(props.formFields.filter((field) => !field.displayOnly));
  }, [props.formFields]);

  useEffect(() => {
    const defaultValues = {};
    formFields.map((field, index) => {
      defaultValues[field.fieldName] = field.defaultValue;
    });
    setFieldValues(defaultValues);
  }, [formFields]);

  return (
    <Box
      component="form"
      sx={{
        paddingLeft: 1,
        paddingRight: 1,
        marginTop: 8,
        paddingTop: 2,
        "& .MuiFormControl-root": {
          tablet: {
            marginTop: 10,
            marginLeft: 10,
            minHeight: 80,
            width: "48%",
          },
          mobile: { marginTop: 15, width: "100%" },
        },
      }}
      noValidate
      autoComplete="off"
      onSubmit={props.handleSubmit}
      key={props.boxKey}
      id={props.formId}
    >
      {formFields.map((field, index) => {
        return (
          <FieldComponent
            field={field}
            document={props.document}
            fieldValues={fieldValues}
            onFieldValueChange={onFieldValueChange}
            key={`FieldComponent-${field.fieldName}-${index}`}
            fieldIndex={0}
          />
        );
      })}
    </Box>
  );
}

Form.propTypes = {
  formFields: PropTypes.array,
  handleSubmit: PropTypes.func,
  boxKey: PropTypes.string,
  formId: PropTypes.string,
  document: PropTypes.string,
};
