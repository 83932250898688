import { Grid, Paper } from "@mui/material";
import Filter from "../../components/filters/Filter";
import Card from "./Card";
import { useCurrencyDisplay } from "../../helper/currencies";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { useState } from "react";
import { Api } from "../../helper/Api";

export default function InvoiceDashboard() {
  const [invoicesInfo, setInvoicesInfo] = useState({
    total_invoices: 0,
    total_invoiced_lines: 0,
    total_invoiced_amount: 0,
    total_paid: 0,
    total_due: 0,
    total_due_amount: 0,
    top_customers: [],
    top_products: [],
  });
  const currencyDisplay = useCurrencyDisplay();
  const defaultFilter = [
    {
      field: "invoice_date",
      values: [],
      operator: "today",
    },
    {
      field: "status",
      values: ["draft"],
      operator: "not_equal",
    },
  ];

  const getInvoiceDashboardInfo = (filters) => {
    const invoiceFilter = filters ? filters : { filters: defaultFilter };
    if (invoiceFilter.filters.length === 0) {
      invoiceFilter["filters"] = defaultFilter;
    }
    Api.get("/dashboards/invoices", invoiceFilter, setInvoicesInfo);
  };
  return (
    <Grid container spacing={0.5}>
      <Grid item mobile={12} tablet={12} sx={{ mb: 2 }}>
        <Filter
          title="Invoices"
          filterCallback={getInvoiceDashboardInfo}
          selectedFilters={defaultFilter}
          fields={[
            {
              label: "Invoice Date",
              defaultValue: "",
              fieldType: "date",
              fieldName: "invoice_date",
            },
            {
              label: "Invoice Status",
              defaultValue: "",
              fieldType: "text",
              fieldName: "status",
            },
          ]}
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card title={invoicesInfo.total_invoices} subTitle="Total Invoices" />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card
          title={invoicesInfo.total_invoiced_lines}
          subTitle="Total Invoiced Lines"
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card
          title={currencyDisplay(invoicesInfo.total_invoiced_amount)}
          subTitle="Total Invoiced Amount"
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card
          title={currencyDisplay(invoicesInfo.total_paid)}
          subTitle="Total Paid Amount"
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card title={invoicesInfo.total_due} subTitle="Total Due" />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <Card
          title={currencyDisplay(invoicesInfo.total_due_amount)}
          subTitle="Total Due Amount"
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <h4>Top 10 Customers By Invoiced Amount</h4>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicesInfo.top_customers.map((customerData, idx) => {
                return (
                  <TableRow key={`top-cs-${idx}`}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{customerData.id}</TableCell>
                    <TableCell>{customerData.name}</TableCell>
                    <TableCell>{currencyDisplay(customerData.total)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <h4>Top 10 Products By Invoiced Amount</h4>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicesInfo.top_products.map((productData, idx) => {
                return (
                  <TableRow key={`top-cs-${idx}`}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{productData.id}</TableCell>
                    <TableCell>{productData.name}</TableCell>
                    <TableCell>{currencyDisplay(productData.total)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
}
