import { createSlice } from "@reduxjs/toolkit";

export const RSSlice = createSlice({
  name: "raseethStore",
  initialState: {
    openedForms: [],
    callRSAutocompleteRefresh: false,
    documentIdSettings: [],
    metadata: {},
    generalSettings: {},
    loggedInUser: {},
    appFeatures: [],
    customObjects: [],
  },
  reducers: {
    closeForm: (state) => {
      state.openedForms.pop();
    },
    openForm: (state, action) => {
      state.openedForms.push(action.payload);
    },
    callRSAutocompleteRefresh: (state) => {
      // This reducer is defined for handling refresh callback on RSAutocomplete component
      state.callRSAutocompleteRefresh = !state.callRSAutocompleteRefresh;
    },
    storeDocumentIdSettings: (state, action) => {
      state.documentIdSettings = action.payload;
    },
    storeMetadata: (state, action) => {
      state.metadata = action.payload;
    },
    storeGeneralSettings: (state, action) => {
      state.generalSettings = action.payload;
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
    setAppFeatures: (state, action) => {
      state.appFeatures = action.payload;
    },
    storeCustomObjects: (state, action) => {
      state.customObjects = action.payload;
    },
  },
});

export const {
  closeForm,
  openForm,
  callRSAutocompleteRefresh,
  storeDocumentIdSettings,
  storeMetadata,
  storeGeneralSettings,
  setLoggedInUser,
  setAppFeatures,
  storeCustomObjects,
} = RSSlice.actions;
