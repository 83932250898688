import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";
import { useSelector } from "react-redux";

export default function RSSelectInput(props) {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);

  const defaultCurrency = useSelector(
    (state) => state.generalSettings.currency,
  );

  // useEffect(() => {
  //   const selectedOption = options.find(
  //     (option) => option.id === props.defaultValue
  //   );
  //   if (selectedOption) {
  //     setValue(props.defaultValue);
  //   }
  // }, [props.defaultValue]);

  const optionMenuItem = (label, value) => {
    return (
      <MenuItem value={value} key={`option-value-${value}`}>
        {label}
      </MenuItem>
    );
  };

  useEffect(() => {
    // Currency rate specific implementation
    if (
      options &&
      options.length > 0 &&
      props.fieldName === "currency_id" &&
      !props.defaultValue
    ) {
      setValue(defaultCurrency.id);
      triggerChange(defaultCurrency.id);
    } else if (options && options.length > 0 && !props.defaultValue && !value) {
      setValue(options[0].id);
      triggerChange(options[0].id);
    } else if (props.defaultValue || value) {
      if (options.length === 0) {
        setValue("");
        triggerChange("");
      } else if (props.defaultValue) {
        const selectedOption = options.find(
          (option) => option.id === props.defaultValue,
        );
        if (selectedOption) {
          setValue(props.defaultValue);
          triggerChange(props.defaultValue);
        } else {
          setValue(options[0].id);
          triggerChange(options[0].id);
        }
      }
    }
  }, [options]);

  const triggerChange = (changedValue) => {
    if (props.onChange) {
      props.onChange(props.fieldName, changedValue);
    }
  };

  useEffect(() => {
    if (props.optionEndpoint) {
      Api.get(props.optionEndpoint, {}, (data) => {
        setValue("");
        setOptions(data);
      });
    } else if (props.availableValues) {
      setOptions(props.availableValues);
    }
  }, [props.optionEndpoint, props.availableValues]);

  return (
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        labelId={`simple-select-label-${props.label}`}
        label={props.label}
        value={value}
        name={props.fieldName}
        onChange={(event) => {
          setValue(event.target.value);
          if (props.onChange) {
            props.onChange(props.fieldName, event.target.value);
          }
        }}
      >
        {options.map((option) => optionMenuItem(option.label, option.id))}
      </Select>
    </FormControl>
  );
}

RSSelectInput.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  availableValues: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  optionEndpoint: PropTypes.string,
};
