import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";

export default function ShowPageTitle(props) {
  return (
    <Box sx={{ mb: 3 }}>
      <Grid container>
        <Grid mobile={10} tablet={10}>
          <Typography
            variant={props.variant ? props.variant : "h1"}
            sx={{ fontSize: { tablet: "3rem", mobile: "2rem" } }}
          >
            {props.title}
          </Typography>
        </Grid>
        <Grid mobile={2} tablet={2}>
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
}

ShowPageTitle.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
};
