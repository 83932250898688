import { Container } from "@mui/material";
import RSBreadcrumb from "../../../components/common/RSBreadcrumb";
import ListHeader from "../../../components/common/ListHeader";
import ListTable from "../../../components/common/ListTable";
import { useEffect, useState } from "react";
import { Api } from "../../../helper/Api";

export const formFields = [
  {
    label: "Name",
    defaultValue: "",
    required: true,
    fieldType: "text",
    fieldName: "name",
  },
  {
    label: "Class Type",
    defaultValue: "product",
    required: true,
    fieldType: "radio",
    fieldName: "class_type",
    availableValues: ["product", "customer"],
  },
];
export default function TaxClassList() {
  const [taxClasses, setTaxClasses] = useState([]);

  const refreshTaxClasses = () => {
    Api.get("/settings/tax_classes", {}, setTaxClasses);
  };

  useEffect(() => {
    refreshTaxClasses();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Settings", link: "" },
          { label: "Tax", link: "" },
          { label: "Classes", link: "" },
        ]}
      />
      <ListHeader
        title="Tax Classes"
        addCallback={refreshTaxClasses}
        formFields={formFields}
        endpoint="/settings/tax_classes"
        variant="h1"
        document="TaxClass"
      />
      <ListTable
        fields={formFields}
        rows={taxClasses}
        editEndpoint="/settings/tax_classes/{id}"
        editCallback={refreshTaxClasses}
        deleteEndpoint="/settings/tax_classes/{id}"
        deleteCallback={refreshTaxClasses}
        showEndpoint="/settings/tax_classes/{id}"
        document="TaxClass"
        title="Tax Class"
        hiddenActions={["view"]}
      />
    </Container>
  );
}
