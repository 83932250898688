import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";

export default function RSTextArea(props) {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <TextField
      label={props.label}
      name={props.fieldName}
      variant="outlined"
      required={props.required}
      onChange={(event) => {
        setValue(event.target.value);
        if (props.onChange) {
          props.onChange(props.fieldName, event.target.value);
        }
      }}
      value={value}
      fullWidth
      multiline
      minRows={25}
    />
  );
}

RSTextArea.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  fieldName: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
