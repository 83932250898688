import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PropTypes from "prop-types";

export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    props.handleEdit(props.actionableObject);
    handleClose();
  };

  const handleDelete = () => {
    props.handleDelete(props.actionableObject);
    handleClose();
  };

  const navigateToShow = () => {
    props.navigateToShow(props.actionableObject);
    handleClose();
  };

  const actionableObjectId = (actionableObjec) => {
    return actionableObjec.document_identifier
      ? actionableObjec.document_identifier
      : actionableObjec.id;
  };

  return (
    <div>
      <IconButton
        aria-label="Actions"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        actionable-object-id={actionableObjectId(props.actionableObject)}
      >
        {props.hiddenActions && props.hiddenActions.includes("view") ? (
          ""
        ) : (
          <MenuItem
            key={`view-menu-${props.actionableObject.id}`}
            onClick={navigateToShow}
          >
            <VisibilityOutlinedIcon />
            &nbsp;View
          </MenuItem>
        )}

        {props.hiddenActions && props.hiddenActions.includes("edit") ? (
          ""
        ) : (
          <MenuItem
            key={`edit-menu-${props.actionableObject.id}`}
            onClick={handleEdit}
          >
            <EditOutlinedIcon />
            &nbsp;Edit
          </MenuItem>
        )}
        {props.hiddenActions && props.hiddenActions.includes("delete") ? (
          ""
        ) : (
          <MenuItem
            key={`delete-menu-${props.actionableObject.id}`}
            onClick={handleDelete}
          >
            <DeleteSweepOutlinedIcon />
            &nbsp;Delete
          </MenuItem>
        )}
        {props.children}
      </Menu>
    </div>
  );
}

ActionMenu.propTypes = {
  actionableObject: PropTypes.object,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  navigateToShow: PropTypes.func,
  hiddenActions: PropTypes.array,
};
