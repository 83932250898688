export const setStateEndpoint = (countryCode, formFields, setFormFields) => {
  setFormFields(
    formFields.map((field) => {
      const newField = Object.assign({}, field);
      if (
        newField.fieldName === "state" ||
        newField.fieldName.includes("[state]")
      ) {
        if (newField.optionEndpoint && countryCode) {
          newField.optionEndpoint = newField.optionEndpoint.replace(
            /country_code=([A-Z]{3})/i,
            `country_code=${countryCode}`,
          );
        }
        newField.defaultValue = "";
      }
      return newField;
    }),
  );
};
