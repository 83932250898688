import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import { Grid, Link } from "@mui/material";
import ActionMenu from "./ActionMenu";
import FormDialogue from "./FormDialogue";
import { useEffect, useState } from "react";
import ConfirmationDialogue from "./ConfirmationDialogue";
import { Api } from "../../helper/Api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { rowLabel } from "../../helper/TransformHelper";
import { useCurrencySymbol } from "../../helper/currencies";

export default function ListTable(props) {
  const currencySymbol = useCurrencySymbol();
  const [editFormOpened, setEditFormOpened] = useState(false);
  const [editFormFields, setEditFormFields] = useState([]);
  const [editEndpoint, setEditEndpoint] = useState("");
  const [deleteConfirmOpened, setDeleteConfirmOpened] = useState(false);
  const [deleteEndpoint, setDeleteEndpoint] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const displayFields = props.fields.filter((field) => !field.hideInList);
  const headers = displayFields.map((field) => {
    return field.label;
  });
  const navigate = useNavigate();

  const tableCell = (label, id) => {
    return <TableCell key={id}>{label}</TableCell>;
  };

  const handleEdit = (actionableObject) => {
    if (props.setEditFormFields) {
      props.setEditFormFields(
        actionableObject,
        setEditFormFields,
        setEditFormOpened
      );
    } else {
      setEditFormFields(
        props.fields.map((field) => {
          const newField = Object.assign({}, field);
          newField.defaultValue = actionableObject[field.fieldName];
          return newField;
        })
      );
      setEditFormOpened(true);
    }
    setEditEndpoint(
      props.editEndpoint.replace("{id}", getDocumentId(actionableObject))
    );
  };

  const handleDelete = (actionableObject) => {
    setDeleteEndpoint(
      props.deleteEndpoint.replace("{id}", getDocumentId(actionableObject))
    );
    setDeleteConfirmOpened(true);
  };

  const confirmationCallback = () => {
    setDeleteConfirmOpened(false);
    Api.delete(
      deleteEndpoint,
      () => {
        props.deleteCallback();
        enqueueSnackbar("Successfully deleted", {
          variant: "success",
        });
      },
      (errorData) => {
        enqueueSnackbar(errorData.message, {
          variant: "error",
        });
        props.deleteCallback();
      }
    );
  };

  const cancelCallback = () => {
    setDeleteConfirmOpened(false);
  };

  const getDocumentId = (actionableObject) => {
    return actionableObject.document_identifier
      ? actionableObject.document_identifier
      : actionableObject.id;
  };

  const navigateToShow = (actionableObject) => {
    const showPath = props.showEndpoint.replace(
      "{id}",
      getDocumentId(actionableObject)
    );
    navigate(showPath, { replace: false });
  };

  const formattedLabel = (rowData, field) => {
    if (field.fieldName === "document_identifier") {
      return (
        <Link
          onClick={() => {
            navigateToShow(rowData);
          }}
          sx={{ cursor: "pointer" }}
        >
          {rowLabel(rowData, field, currencySymbol)}
        </Link>
      );
    }

    return rowLabel(rowData, field, currencySymbol);
  };

  const tableRow = (rowData) => {
    return (
      <TableRow key={rowData.id}>
        {displayFields.map((field) => {
          return tableCell(
            formattedLabel(rowData, field),
            `${rowData.id}-${field.fieldName}`
          );
        })}
        {props.hideActions ? (
          ""
        ) : (
          <TableCell key={`${rowData.id}-actions`}>
            <ActionMenu
              actionableObject={rowData}
              hiddenActions={props.hiddenActions}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              navigateToShow={navigateToShow}
            >
              {props.additionalActionMenu}
            </ActionMenu>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <Grid container sx={{ overflow: "auto" }}>
      <Table size="medium">
        <TableHead>
          <TableRow key="header-row">
            {headers.map((headerLabel) => {
              return tableCell(headerLabel, `header-${headerLabel}`);
            })}
            {props.hideActions ? "" : <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((rowData) => {
            return tableRow(rowData);
          })}
          {props.rows.length < 1 ? (
            <TableRow>
              <TableCell colSpan={headers.length + 1} align="center">
                No data
              </TableCell>
            </TableRow>
          ) : (
            ""
          )}
        </TableBody>
      </Table>
      <FormDialogue
        open={editFormOpened}
        handleClose={() => {
          setEditFormOpened(false);
          props.editCallback();
        }}
        title={`Edit ${props.title}`}
        formFields={editFormFields}
        endpoint={editEndpoint}
        apiAction="put"
        document={props.document}
      />
      <ConfirmationDialogue
        confirmationCallback={confirmationCallback}
        description="Are you sure to delete ?"
        open={deleteConfirmOpened}
        cancelCallback={cancelCallback}
      />
    </Grid>
  );
}

ListTable.propTypes = {
  fields: PropTypes.array,
  rows: PropTypes.array,
  editEndpoint: PropTypes.string,
  editCallback: PropTypes.func,
  deleteEndpoint: PropTypes.string,
  deleteCallback: PropTypes.func,
  showEndpoint: PropTypes.string,
  title: PropTypes.string,
  hiddenActions: PropTypes.array,
  document: PropTypes.string,
  hideActions: PropTypes.bool,
  setEditFormFields: PropTypes.func,
  additionalActionMenu: PropTypes.any,
};
