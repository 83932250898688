import { Breadcrumbs, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

export default function RSBreadcrumb(props) {
  const navigate = useNavigate();

  const onLinkClick = (event) => {
    event.preventDefault();
    const url = event.currentTarget.getAttribute("href");
    navigate(url, { replace: false });
  };

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="primary"
          href="/home"
          key="home-breadcrumb"
          onClick={onLinkClick}
        >
          Home
        </Link>
        {props.menuStack.map((menu, index) => {
          if (index === props.menuStack.length - 1) {
            return (
              <Typography color="secondary" key={`breadcrumb-${menu.label}`}>
                {menu.label}
              </Typography>
            );
          } else {
            return (
              <Link
                underline="hover"
                color="primary"
                href={menu.link}
                key={`breadcrumb-${menu.label}`}
                onClick={onLinkClick}
              >
                {menu.label}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
    </div>
  );
}

RSBreadcrumb.propTypes = {
  menuStack: PropTypes.arrayOf(PropTypes.object),
};
