import { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import PropTypes from "prop-types";

export default function RSRadioInput(props) {
  const [value, setValue] = useState(props.defaultValue);

  const formControlLabel = (label) => {
    return (
      <FormControlLabel
        key={`form-control-label-${label}`}
        value={label}
        control={<Radio />}
        label={label}
        sx={{ textTransform: "capitalize" }}
      />
    );
  };
  return (
    <FormControl>
      <FormLabel id="radio-buttons-group-label">{props.label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="radio-buttons-group-label"
        value={value}
        name={props.fieldName}
        onChange={(event) => {
          setValue(event.target.value);
          if (props.onChange) {
            props.onChange(props.fieldName, event.target.value);
          }
        }}
      >
        {props.availableValues.map((label) => {
          return formControlLabel(label);
        })}
      </RadioGroup>
    </FormControl>
  );
}
RSRadioInput.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  fieldName: PropTypes.string,
  availableValues: PropTypes.array,
  onChange: PropTypes.func,
};
