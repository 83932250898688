import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FormDialogue from "./FormDialogue";
import { useState } from "react";
import Filter from "../filters/Filter";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ImportDialogue from "./ImportDialogue";

export default function ListHeader(props) {
  const [addFormOpened, setAddFormOpened] = useState(false);
  const [importDialogueOpened, setImportDialogueOpened] = useState(false);
  const addClicked = () => {
    setAddFormOpened(true);
  };
  const importClicked = () => {
    setImportDialogueOpened(true);
  };
  return (
    <Grid container>
      <Grid mobile={12} tablet={12}>
        <Grid container>
          <Grid mobile={9} tablet={9}>
            <Typography
              variant={props.variant}
              sx={{
                fontSize: { tablet: "3rem", mobile: "2rem" },
              }}
            >
              {props.title}
            </Typography>
          </Grid>
          <Grid mobile={3} tablet={3}>
            <Box>
              {props.importUrl ? (
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<LibraryBooksIcon />}
                  sx={{
                    marginTop: { tablet: "10px" },
                    marginLeft: "5px",
                    marginY: "5px",
                  }}
                  style={{ float: "right" }}
                  onClick={importClicked}
                >
                  Import
                </Button>
              ) : (
                ""
              )}

              <Button
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{ marginTop: { tablet: "10px" } }}
                style={{ float: "right" }}
                onClick={addClicked}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {props.filterCallback ? (
        <Grid mobile={12} tablet={12}>
          <Filter
            id={`filter-${props.title}`}
            title={props.title}
            filterCallback={props.filterCallback}
            fields={props.formFields}
          />
        </Grid>
      ) : (
        ""
      )}
      <FormDialogue
        open={addFormOpened}
        handleClose={() => {
          setAddFormOpened(false);
          props.addCallback();
        }}
        title={`Add ${props.title}`}
        formFields={props.formFields}
        endpoint={props.endpoint}
        apiAction="post"
        document={props.document}
      />
      <ImportDialogue
        open={importDialogueOpened}
        handleClose={() => {
          setImportDialogueOpened(false);
          props.addCallback();
        }}
        title={`Import ${props.title}`}
        formFields={props.formFields}
        endpoint={props.importUrl}
        apiAction="post"
        document={props.document}
      />
    </Grid>
  );
}

ListHeader.propTypes = {
  title: PropTypes.string,
  addCallback: PropTypes.func,
  formFields: PropTypes.array,
  endpoint: PropTypes.string,
  variant: PropTypes.string,
  filterCallback: PropTypes.func,
  document: PropTypes.string,
  importUrl: PropTypes.string,
};
