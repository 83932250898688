import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ListHeader from "../../components/common/ListHeader";
import ListTable from "../../components/common/ListTable";
import { Container, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { Api } from "../../helper/Api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

export default function StatementList() {
  const [statements, setStatements] = useState([]);
  const statementFields = useSelector((state) => state.metadata["Statement"]);
  const [selectedStatementId, setSelectedStatementId] = useState("");
  const navigate = useNavigate();

  const refreshStatementList = (params = {}) => {
    Api.get("/statements", params, setStatements);
  };

  const getStatementId = (event) => {
    return event.target.parentNode.parentNode.parentNode.getAttribute(
      "actionable-object-id"
    );
  };

  const downloadPdf = (event) => {
    const downloadUrl = `${
      process.env.API_BASE_URL
    }/statements/${getStatementId(event)}/pdf?jwt_token=${localStorage.getItem(
      "access_token"
    )}`;
    window.open(downloadUrl, "_blank").focus();
  };

  const printPdf = (event) => {
    const printUrl = `/statements/${getStatementId(event)}/print`;
    navigate(printUrl, { replace: false });
  };

  const additionalMenuItems = [
    <MenuItem onClick={downloadPdf} key="Download-Menu">
      <DownloadIcon />
      &nbsp;Download PDF
    </MenuItem>,
    <MenuItem onClick={printPdf} key="Print-Menu">
      <PrintIcon />
      &nbsp;Print
    </MenuItem>,
  ];

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb menuStack={[{ label: "Statements", link: "" }]} />
      <ListHeader
        title="Statements"
        addCallback={refreshStatementList}
        formFields={statementFields}
        endpoint="/statements"
        variant="h1"
        filterCallback={refreshStatementList}
        document="Statement"
      />
      <ListTable
        fields={statementFields}
        rows={statements}
        editEndpoint="/statements/{id}"
        editCallback={refreshStatementList}
        deleteEndpoint="/statements/{id}"
        deleteCallback={refreshStatementList}
        title="Statements"
        hiddenActions={["edit"]}
        showEndpoint="/statements/{id}"
        document="Statement"
        additionalActionMenu={additionalMenuItems}
      />
    </Container>
  );
}
