import RSDocumentID from "../inputs/RSDocumentID";
import RSTextField from "../inputs/RSTextField";
import RSToggleSwitch from "../inputs/RSToggleSwitch";
import RSSelectInput from "../inputs/RSSelectInput";
import RSRadioInput from "../inputs/RSRadioInput";
import RSAutocomplete from "../inputs/RSAutocomplete";
import RSDateField from "../inputs/RSDateField";
import { useAutoId } from "../../helper/fields/documentIdentifier";
import PropTypes from "prop-types";
import RSAssociationField from "../inputs/RSAssociationField";
import RSTextArea from "../inputs/RSTextArea";
import RSFileUpload from "../inputs/RSFileUpload";

export default function FieldComponent(props) {
  const field = props.field;
  const isAutoDocumentId = useAutoId(props.document);

  const showField = (field) => {
    // Show only if the condition specified on the property showOnly is met, if not specified always visible
    if (!field.showOnly) {
      return true;
    }
    let displayField = false;

    for (let ind = 0; ind < field.showOnly.length; ind++) {
      const condition = field.showOnly[ind];
      displayField = condition.values.includes(
        props.fieldValues[condition.fieldName]
      );
      if (!displayField) {
        break;
      }
    }

    return displayField;
  };

  if (!showField(field)) {
    return <></>;
  }

  switch (field.fieldType) {
    case "text":
      if (isAutoDocumentId && field.fieldName.includes("document_identifier")) {
        const fieldName = field.defaultValue ? field.fieldName : "";
        return (
          <RSDocumentID
            key={`field-${field.fieldName}`}
            defaultValue={field.defaultValue}
            fieldName={fieldName}
          />
        );
      }
      return (
        <RSTextField
          fieldName={field.fieldName}
          label={field.label}
          required={field.required}
          defaultValue={field.defaultValue}
          key={`field-${field.fieldName}`}
          onChange={props.onFieldValueChange}
          isPassword={field.isPassword}
        />
      );
    case "textArea":
      return (
        <RSTextArea
          fieldName={field.fieldName}
          label={field.label}
          required={field.required}
          defaultValue={field.defaultValue}
          key={`field-${field.fieldName}`}
          onChange={props.onFieldValueChange}
        />
      );
    case "toggle":
      return (
        <RSToggleSwitch
          key={`field-${field.fieldName}`}
          label={field.label}
          defaultValue={field.defaultValue}
          fieldName={field.fieldName}
          onChange={props.onFieldValueChange}
        />
      );
    case "select":
      return (
        <RSSelectInput
          key={`field-${field.fieldName}`}
          fieldName={field.fieldName}
          label={field.label}
          defaultValue={field.defaultValue}
          availableValues={field.availableValues}
          optionEndpoint={field.optionEndpoint}
          onChange={props.onFieldValueChange}
        />
      );
    case "radio":
      return (
        <RSRadioInput
          key={`field-${field.fieldName}`}
          fieldName={field.fieldName}
          label={field.label}
          required={field.required}
          defaultValue={field.defaultValue}
          availableValues={field.availableValues}
          onChange={props.onFieldValueChange}
        />
      );
    case "association":
      return (
        <RSAssociationField
          field={field}
          onFieldValueChange={props.onFieldValueChange}
          document={field.document}
          parentDocument={props.document}
        />
      );
    case "autocomplete":
      return (
        <RSAutocomplete
          key={`field-${field.fieldName}`}
          label={field.label}
          fieldName={field.fieldName}
          optionEndpoint={field.optionEndpoint}
          defaultValue={field.defaultValue}
          addFormFields={field.addFormFields}
          addTitle={field.addTitle}
          addEndpoint={field.addEndpoint}
          document={field.document}
          disabled={field.disabled}
          onChange={props.onFieldValueChange}
          multiple={field.multiple}
        />
      );
    case "date":
      return (
        <RSDateField
          key={`field-${field.fieldName}`}
          label={field.label}
          defaultValue={field.defaultValue}
          fieldName={field.fieldName}
          onChange={props.onFieldValueChange}
        />
      );
    case "file":
      return (
        <RSFileUpload
          label={field.label}
          fieldName={field.fieldName}
          defaultValue={field.defaultValue}
        />
      );
  }
}

FieldComponent.propTypes = {
  field: PropTypes.object,
  document: PropTypes.string,
  fieldValues: PropTypes.object,
  onFieldValueChange: PropTypes.func,
};
