import { useParams } from "react-router-dom";
import { toPascalCase } from "../../helper/TransformHelper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ListHeader from "../../components/common/ListHeader";
import ListTable from "../../components/common/ListTable";
import { Container, MenuItem } from "@mui/material";
import { Api } from "../../helper/Api";
import WebhookIcon from "@mui/icons-material/Webhook";
import WebhookLogListDialog from "../../components/common/WebhookLogListDialog";

export default function CustomObjectsTable() {
  const params = useParams();
  const document = toPascalCase(params.customObject);
  const [customObjects, setCustomObjects] = useState([]);
  const formFields = useSelector((state) => state.metadata[document]);
  const [baseObjectsUrl, setBaseObjectUrl] = useState(
    `/custom_objects/${params.customObject}`,
  );
  const [webhookDocumentId, setWebhookDocumentId] = useState(0);
  const [openWebhookLogDialogue, setOpenWebhookLogDialogue] = useState(false);

  useEffect(() => {
    setBaseObjectUrl(`/custom_objects/${params.customObject}`);
  }, [document]);

  useEffect(() => {
    refreshCustomObjectsList();
  }, [baseObjectsUrl]);

  const refreshCustomObjectsList = (params = {}) => {
    Api.get(`${baseObjectsUrl}/list`, params, setCustomObjects);
  };

  const getDocumentId = (menuClickEvent) => {
    return parseInt(
      menuClickEvent.target.parentNode.parentNode.parentNode.getAttribute(
        "actionable-object-id",
      ),
    );
  };

  const webhookEventClicked = (menuClickEvent) => {
    setWebhookDocumentId(getDocumentId(menuClickEvent));
    setOpenWebhookLogDialogue(true);
  };

  const closeWebhookLogDialogue = () => {
    setOpenWebhookLogDialogue(false);
  };

  const additionalMenu = [
    <MenuItem onClick={webhookEventClicked} key="Webhook-Menu">
      <WebhookIcon />
      &nbsp;Events
    </MenuItem>,
  ];

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb
        menuStack={[
          { label: "Custom Objects", link: "#" },
          { label: document, link: "#" },
        ]}
      />
      <ListHeader
        title={params.customObject}
        addCallback={refreshCustomObjectsList}
        formFields={formFields}
        endpoint={`${baseObjectsUrl}/create_object`}
        variant="h1"
        document={document}
        filterCallback={refreshCustomObjectsList}
        importUrl="/imports"
      />
      <ListTable
        fields={formFields}
        rows={customObjects}
        editEndpoint={`${baseObjectsUrl}/{id}`}
        showEndpoint={`${baseObjectsUrl}/{id}`}
        title={params.customObject}
        document={document}
        editCallback={refreshCustomObjectsList}
        deleteEndpoint={`${baseObjectsUrl}/{id}`}
        deleteCallback={refreshCustomObjectsList}
        additionalActionMenu={additionalMenu}
      />
      <WebhookLogListDialog
        document={document}
        documentId={webhookDocumentId}
        open={openWebhookLogDialogue}
        handleClose={closeWebhookLogDialogue}
      />
    </Container>
  );
}
